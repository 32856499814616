import {PreCreationGemUser} from "../../../api/entityApis/UserApi";

export const convertFormToUser = (data: any): PreCreationGemUser => {

    console.debug(data);

    const authorities = []

    if (data.isUser)
    {
        authorities.push("ROLE_GEM_USER")
    }

    if (data.isAdmin)
    {
        authorities.push("ROLE_GEM_ADMIN")
    }

    return {
        authorities: authorities,
        email: data.email,
        name: data.name,
        shouldReceiveEmail: data.shouldReceiveEmail
    }
};
