import React from "react";
import {useSelector} from "react-redux";
import {GenerateEntityQRCode} from "../QR/GenerateQRCode";
import {css} from "@emotion/react";
import {labelsPerPage} from "./PrintButton";
import {QRLabelText} from "./QRLabelText";
import {EntityConstants} from "../../constants/EntityConstants";
import {Entity, PreCreationEntity} from "../../api/entityApis/Entity";
import "./newWindow.css";

const gridItemStyles = css`
  text-align: center;
  line-height: 1;
`

const gridContainerStyles = css`
  display: grid;
  width: 100%;
  height: 100%;
  grid-row-gap: 3.2mm;
  grid-column-gap: 4.0mm;


  grid-template-columns: 18.2% 18.2% auto 18.2% 18.2%;
  grid-template-rows: 13% 13% 13% 13% 13% 13% 13%;
`

const qrCodeContainerStyles = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80px;
  margin-top: 5%;
`

const labelContainerStyle = css`
  padding-left: 1mm;
  padding-top: 2mm;
  padding-bottom: 2mm;
  //height: 24mm;
  //width: 90mm;
  height: 60mm;
  //width: 24mm;
  width: 18mm;
  display: block;

  //border-color: black;
  //border-width: 2px;
  //border-style: solid;


`

const printWindowStyle = css`
  width: max-content
`


export const PrintWindow = <P extends PreCreationEntity, E extends Entity & P>(props: {
    rows: number[],
    emptyLabels: number,
    entityConstants: EntityConstants<P, E>,
}) => {

    const data = useSelector(props.entityConstants.getData)


    const emptyLabels = Math.min(Math.max(props.emptyLabels, 0), labelsPerPage);
    const numberOfGrids = calculateNumberOfGrids(props.rows.length + emptyLabels);

    // Create a grid container for every 35 rows.
    const gridContainers: JSX.Element[][] = []

    for (let i = 0; i < numberOfGrids; i++) {
        gridContainers.push([])
    }

    let currentGrid = 0;
    let counter = 0;

    // Add in the placeholder grid entries.
    for (let i = 0; i < emptyLabels; i++) {
        gridContainers[0].push(<div className="grid-item"/>)

        counter++;
    }

    // Add in the actual rows.
    for (let row of props.rows) {

        if (counter === labelsPerPage) {
            counter = 0;
            currentGrid++;
        }

        let item = data.find((it: E) => it.id === row);

        if (item) {
            gridContainers[currentGrid].push(
                /*
                <div css={gridItemStyles}>
                    <div css={qrCodeContainerStyles}>
                        <GenerateEntityQRCode css={css`margin-top: 6px`}
                                              uri={props.entityConstants.uri}
                                              id={item.id} size={72}/>
                    </div>
                    <QRLabelText name={props.entityConstants.name} data={item}/>
                </div>
                */
                <div css={labelContainerStyle}>
                    {/*
                    <Grid container xs={12} spacing={2}>
                        <Grid item >
                            {//<GenerateEntityQRCode css={css`margin-top: 6px`}
                            }
                            <GenerateEntityQRCode css={css` margin-top: 5%; margin-bottom: 5%;`}
                                uri={props.entityConstants.uri}
                                id={item.id} size={68}/>
                        </Grid>
                        <Grid item>
                            <QRLabelText name={props.entityConstants.name} data={item}/>
                        </Grid>
                    </Grid>
                    */
                    }
                    <GenerateEntityQRCode  //css={css` margin-top: 5%; margin-bottom: 5%;`}
                                          uri={props.entityConstants.uri}
                                          id={item.id} size={64}/>
                    <QRLabelText css={css`transform:rotate(90deg)`} name={props.entityConstants.name} data={item}/>
                </div>
            );

        }

        counter++;
    }

    return (
        <section id={"labelPrintPage"}>
        <div id="printWindowContainer" css={printWindowStyle}>
            {gridContainers.map(container => <>{container}</>)}
        </div>
        </section>
    );


    /*
    return (
        <>
            {gridContainers.map(container => <div css={gridContainerStyles}>{container}</div>)}
        </>
    );


     */
    /*
    return (<>
    <div css={labelContainerStyle}>
            <GenerateEntityQRCode
                                  uri={props.entityConstants.uri}
                                  id={item.id} size={72}/>
        <QRLabelText name={props.entityConstants.name} data={item}/>
    </div>
    </>);
    */

}

const calculateNumberOfGrids = (labels: number) => {
    return (Math.ceil(labels / labelsPerPage));
}
