import {SvgIconProps} from "@mui/material/SvgIcon/SvgIcon";
import HistoryIcon from '@mui/icons-material/History';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import AssignmentIcon from '@mui/icons-material/Assignment';
import VerifiedUser from "@mui/icons-material/VerifiedUser"
import RoomIcon from '@mui/icons-material/Room';
import WavesIcon from '@mui/icons-material/Waves';
import {Location} from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';

interface TabData {
    text: string,
    route: string,
    icon: (props: SvgIconProps) => JSX.Element
}

/**
 * The data used to generate the navigation tabs. Edit this to add/remove navigation options.
 */
export const tabData: TabData[] = [
    {
        text:"Home",
        route:"home",
        icon:HomeIcon

    },
    {
        text: "Regulators",
        route: "regulators",
        icon: AvTimerIcon
    },
    {
        text: "Locations",
        route: "locations",
        icon: RoomIcon
    },
    {
        text: "Audit",
        route: "audit",
        icon: AssignmentIcon
    }
];

export const hamburgerTabData: TabData[] = [
    {
        text: "Audit History",
        route: "auditHistory",
        icon: HistoryIcon
    },
    {
        text: "Gas Types",
        route: "gasTypes",
        icon: WavesIcon
    },
]

export const userTab: TabData = {
    text: "Users",
    route: "users",
    icon: VerifiedUser
};

export const getActiveTab = (tabs: TabData[], currentRoute: Location): number | boolean => {

    const path = currentRoute.pathname.split("/")[1];

    // Find the tab whose route matches the current route.
    let activeTab = tabs.findIndex(tab => tab.route === path);

    // Home goes to regulators.
    if (currentRoute.pathname === "/") {
        return 0
    }

    if (activeTab === -1) {
        return false
    }

    return activeTab
}