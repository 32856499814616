import {CrudReducer} from "./crudReducer";
import {GasType, GasTypeApi} from "../../api/entityApis/GasTypeApi";

class GasTypeReducer extends CrudReducer<GasType>
{
    getApi() {
        return GasTypeApi;
    }

    getName() {
        return "gasType";
    }
}

export const gasTypeReducer = new GasTypeReducer();
