import {useEffect, useRef} from "react";

// TODO rather than this can we use useEffect({}, []) - it'll be post-mount rather than pre-mount.
export const usePreMount = (func: () => void) => {
    const willMount = useRef(true);

    if (willMount.current) {
        func();
    }

    useEffect(() => {
        willMount.current = false;
    }, []);
};
