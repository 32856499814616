import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../store";
import {WhoAmIApi} from "../../api/WhoAmIApi";

interface SettingsState {
    darkMode: boolean;
    isAdmin: boolean;
}

const initialState: SettingsState = {
    darkMode: false,
    isAdmin: false
};

const settingsSlice = createSlice({
    name: "settings",
    initialState: initialState,
    reducers: {
        setIsAdmin: (state, action: PayloadAction<boolean>) => {
            state.isAdmin = action.payload
        },
        setDarkMode: (state, action: PayloadAction<boolean>) => {
            state.darkMode = action.payload
        },
        toggleDarkMode: (state) => {
            state.darkMode = !state.darkMode;
            return state
        }
    }
});

export const whoAmI = (): AppThunk => async dispatch => {
    const api = new WhoAmIApi();
    const response = await api.whoAmI();
    const json = await response.json();
    const isAdmin = json["authorities"].includes("ROLE_GEM_ADMIN")

    dispatch(settingsSlice.actions.setIsAdmin(isAdmin))
}

export const {setDarkMode, toggleDarkMode} = settingsSlice.actions;
export const settingsReducer = settingsSlice.reducer;
