import {css} from "@emotion/react";
import React, {useState} from "react";
import sizeMe from "react-sizeme";
import {AppBar, Drawer, useMediaQuery} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import {useNavigate} from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from "@mui/material/Typography";
import MenuIcon from '@mui/icons-material/Menu';
import {useTheme} from '@mui/material/styles';
import {Sidebar} from "./Navigation/Sidebar";

/**
 * Header component.
 * Children can be passed through so that tabs can be rendered in desktop mode.
 */
export const Header = (props: React.PropsWithChildren<{}>) => {

    const [height, setHeight] = useState(0);

    return (
        <header>
            <div style={{height: height}}/>
            <AppBar color="default" position="fixed">
                <SizedHeader onSize={(size) => setHeight(size.height || 0)}>
                    {props.children}
                </SizedHeader>
            </AppBar>
        </header>
    )
};

/**
 * Header component whose height is computed using react-sizeme.
 */
const SizedHeader = sizeMe({monitorHeight: true, monitorWidth: false})
((props: React.PropsWithChildren<{}>) => {

    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [sidebarOpen, setSidebarOpen] = useState(false);

    return (
        <div css={css`padding-top: env(safe-area-inset-top);`}>
            <Container>
                <Toolbar variant={isMobile ? "dense" : "regular"}
                         css={css`display: flex; justify-content: space-between;`}>
                    <IconButton
                        color="inherit"
                        aria-label="Back"
                        onClick={() => navigate(-1)}
                        size="large">
                        <ArrowBackIcon/>
                    </IconButton>
                    <Typography variant="h6">
                        GEM
                    </Typography>
                    <IconButton
                        color="inherit"
                        aria-label="Open Sidebar"
                        onClick={() => setSidebarOpen(true)}
                        size="large">
                        <MenuIcon/>
                    </IconButton>
                    <Drawer
                        anchor="right"
                        open={sidebarOpen}
                        onClose={() => setSidebarOpen(false)}
                    >
                        <Sidebar close={() => setSidebarOpen(false)}/>
                    </Drawer>
                </Toolbar>
                {props.children}
            </Container>
        </div>
    );
});
