// export const serverUrl = "https://192.168.1.2:8443";
export const serverUrl = process.env.REACT_APP_SERVER_URL
    ? process.env.REACT_APP_SERVER_URL
    : "http://localhost:8080";

export abstract class GemApi
{
    handleResponse(response: Response): Response
    {
        switch (response.status) {
            case 200:
            case 201:
                return response;
            case 401:
                window.location.href = `${serverUrl}/oauth2/authorization/microsoft?redirect_uri=${encodeURI(window.location.href)}`
                // return response;
                // TODO deal with this rather than throwing an error. The user can see it before the browser redirects.
                // Make a toast and make this method able to return null.
                throw Error("Unauthorized. Redirecting to login page.");
            default:
                // TODO add response code and message
                throw Error("An unexpected error occurred while querying the GEM server.");
        }
    }
}