import React from "react";
import {useDispatch} from "react-redux";
import Button from "@mui/material/Button";
import {css} from "@emotion/react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import {EntityConstants} from "../../constants/EntityConstants";

/**
 * Delete button used by the CrudTable's multi-select.
 */
export const DeleteButton: React.FC<{
    rows: {id: number}[],
    entityConstants: EntityConstants<any, any>
}> = (props) => {

    const [open, setOpen] = React.useState(false);

    const userText = `${props.rows.length} ${props.entityConstants.name}${props.rows.length > 1 ? "s" : ""}`

    const dispatch = useDispatch<any>();

    const deleteRows = async () => {

        await dispatch(props.entityConstants.reducer.deleteResources(props.rows.map(row => row.id)));
        setOpen(false);
    }

    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                variant="contained"
                color="secondary"
                css={css`margin-right: 26px;`}
            >
                Delete {userText}
            </Button>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle>WARNING</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete {userText}?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setOpen(false)}>Cancel</Button>
                    <Button color="secondary" onClick={deleteRows}>Delete</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
