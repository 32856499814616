import {GemApi, serverUrl} from "./GemApi";

/**
 * Class used for making REST requests to any GEM CRUD controller.
 */
export class CrudApi<Entity, PreCreationEntity> extends GemApi {

    private readonly controller: string;

    constructor(controller: string) {
        super();
        this.controller = `${serverUrl}/v1/${controller}`;
    }

    public async create(resource: PreCreationEntity): Promise<Entity>
    {
        const response = await this.makeRequest(`/`, "POST", resource)
        return await response.json();
    }

    public async edit(id: number, resource: PreCreationEntity): Promise<Entity>
    {
        const response = await this.makeRequest(`/${id}`, "PUT", resource)
        return await response.json();
    }

    public async getAll(): Promise<Entity[]>
    {
        const response = await this.makeRequest(`/`)
        return await response.json();
    }

    public async get(id: number): Promise<Entity>
    {
        const response = await this.makeRequest(`/${id}`)
        return await response.json();
    }

    public async delete(id: number)
    {
        return await this.makeRequest(`/${id}`, "DELETE")
    }

    private async makeRequest(uri: string, method: string = "GET", body?: PreCreationEntity): Promise<Response>
    {
        const config: RequestInit = {
            credentials: "include",
            method: method
        };

        if (method === "PUT" || method === "POST")
        {
            config.body = JSON.stringify(body);
            config.headers = {"Content-Type": "application/json"};
        }

        const response = await fetch(`${this.controller}${uri}`, config);

        return this.handleResponse(response);
    }
}
