import {CrudApi} from "../CrudApi";
import {Entity, PreCreationEntity} from "./Entity";
import {GemLocation} from "./LocationApi";
import {GasType} from "./GasTypeApi";
import {ServiceHistoryEntry} from "./ServiceHistoryEntryApi";

export const RegulatorApi = new CrudApi<Regulator, PreCreationRegulator>("regulators")

/**
 * Interface of the regulator entity coming from the server. This should always match the fields in model.Regulator
 * on the server-side.
 */
export interface Regulator extends PreCreationRegulator, Entity {
}

/**
 * This is the regulator before it has been created on the server side. as such it does not
 * yet have the unique ID generated by the server.
 */
export interface PreCreationRegulator extends PreCreationEntity {
    manufacturer?: string
    modified?: string
    name?: string
    description?: string
    manufacturerPartNum?: string
    inService?: boolean
    serialNum?: string
    type?: string
    manufactureDate?: string
    maxPressure?: string
    serviceDueDate?: string
    location?: GemLocation
    gasTypes: GasType[]
    serviceHistoryEntries?: ServiceHistoryEntry[]
}
