import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {UseFormReturn} from "react-hook-form";

export const SubmitButton: React.FC<{
    form: UseFormReturn
    onSubmit: (data: any) => void
}> = (props) => {
    return (
        <Grid item xs={12}>
            <form onSubmit={props.form.handleSubmit(props.onSubmit)} autoComplete="off">
                <Box m={2}>
                    <Button type="submit" variant="contained" color="primary">
                        Submit
                    </Button>
                </Box>
            </form>
        </Grid>
    );
}
