import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

export const LoadingSpinner = () => {
    return (
        <Box py={8}>
            <Grid container justifyContent="center">
                <CircularProgress/>
            </Grid>
        </Box>
    );
}
