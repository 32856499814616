import {SingleField} from "../Shared/SingleField/SingleField";
import React from "react";
import {ISingleEntityFields} from "../Shared/ISingleEntityFields";
import {PreCreationGemUser} from "../../../api/entityApis/UserApi";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import {Controller} from "react-hook-form";
import {StringField} from "../Shared/SingleField/StringField";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import FaceIcon from '@mui/icons-material/Face';
import HttpsIcon from '@mui/icons-material/Https';
import EmailIcon from '@mui/icons-material/Email';
import Switch from "@mui/material/Switch/Switch";

export const UserFields: React.FC<ISingleEntityFields<PreCreationGemUser>> = (props) => {

    const isUser = props.entity.authorities.includes("ROLE_GEM_USER")
    const isAdmin = props.entity.authorities.includes("ROLE_GEM_ADMIN")

    return (
        <>
            <StringField
                editMode={props.editMode}
                form={props.form}
                value={props.entity.name}
                title="Name"
                name="name"
                icon={FaceIcon}
            />
            <StringField
                editMode={props.editMode}
                form={props.form}
                value={props.entity.email}
                title="Email"
                name="email"
                icon={AlternateEmailIcon}
            />
            <SingleField title="Roles" editMode={props.editMode} icon={HttpsIcon}
                         editComponent={
                             // TODO busted
                             <FormGroup>
                                 <FormControlLabel label="User" control={
                                     <Controller
                                         name="isUser"
                                         control={props.form.control}
                                         defaultValue={isUser}
                                         render={({field}) => <Checkbox defaultChecked={isUser} {...field}/>}
                                     />
                                 }/>
                                 <FormControlLabel label="Admin" control={
                                     <Controller
                                         name="isAdmin"
                                         control={props.form.control}
                                         defaultValue={isAdmin}
                                         render={({field}) => <Checkbox defaultChecked={isAdmin}  {...field}/>}
                                     />
                                 }/>
                             </FormGroup>
                         }
                         viewComponent={
                             <FormGroup>
                                 <FormControlLabel label="User" control={
                                     <Checkbox checked={isUser} disabled/>
                                 }/>
                                 <FormControlLabel label="Admin" control={
                                     <Checkbox checked={isAdmin} disabled/>
                                 }/>
                             </FormGroup>
                         }/>
            <SingleField editMode={props.editMode}
                         title={"Monthly Email Report"}
                         icon={EmailIcon}
                         editComponent={
                             <Controller
                                 name="shouldReceiveEmail"
                                 control={props.form.control}
                                 defaultValue={props.entity.shouldReceiveEmail}
                                 render={({field}) => <Switch defaultChecked={props.entity.shouldReceiveEmail} {...field}/>}
                             />
                         }
                         viewComponent={
                             <Switch checked={props.entity.shouldReceiveEmail} disabled name="shouldReceiveEmail"/>
                         }/>

        </>
    )
};
