import React from "react";
import {NewEntity} from "../Shared/NewEntity";
import {PreCreationGemLocation} from "../../../api/entityApis/LocationApi";
import {locationConstants} from "../../../constants/LocationConstants";

export const NewLocation: React.FC = () => {

    const initialLocation: PreCreationGemLocation = {
        locationCode: ""
    }

    return (
        <NewEntity
            initialEntity={initialLocation}
            entityConstants={locationConstants}
        />
    )
};
