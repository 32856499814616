import {CrudApi} from "../CrudApi";
import {Entity, PreCreationEntity} from "./Entity";

export const UserApi = new CrudApi<GemUser, PreCreationGemUser>("users")

export interface GemUser extends PreCreationGemUser, Entity {
}

export interface PreCreationGemUser extends PreCreationEntity {
    name: string
    email: string
    shouldReceiveEmail: boolean
    authorities: string[]
}
