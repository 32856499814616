import React, {useEffect, useMemo, useState} from "react";
import MUIDataTable, {MUIDataTableColumn, MUIDataTableOptions} from "mui-datatables";
import { createTheme, useTheme } from '@mui/material/styles';
import { ThemeProvider, Theme, StyledEngineProvider, useMediaQuery } from "@mui/material";
import {DeleteButton} from "./DeleteButton";
import {useDispatch} from "react-redux";
import Button from "@mui/material/Button";
import {css} from "@emotion/react";
import {Link, useNavigate} from "react-router-dom";
import {EntityConstants} from "../../constants/EntityConstants";
import {usePreMount} from "../../util/hooks";

interface TableDataItem {
    id: number
}

const URL_PARAM_PAGE = "page";
const URL_PARAM_LIMIT = "limit";
const URL_PARAM_SORT_BY = "sortBy";
const URL_PARAM_SORT_ORDER = "sortOrder";
const URL_PARAM_SEARCH = "search";

export const createTableTheme = (theme: Theme, isMobile: boolean) => {
    return createTheme({
        ...theme,
        components: {
            // @ts-ignore
            MUIDataTableBodyCell: {
                styleOverrides: {
                    stackedHeader: {
                        verticalAlign: "middle",
                        fontWeight: theme.typography.fontWeightBold
                    }
                }
            },
            MUIDataTableToolbarSelect: {
                styleOverrides: {
                    root: {
                        minHeight: isMobile ? "56px" : "64px"
                    }
                }
            }
        }
    });
}


export const CrudTable: React.FC<{
    tableColumns: MUIDataTableColumn[],
    tableOptions?: MUIDataTableOptions,
    tableData: TableDataItem[],
    entityConstants: EntityConstants<any, any>,
    extraToolbarSelectComponents?: React.FC<{entityConstants: EntityConstants<any, any>, rows: number[]}>[]
}> = (props) => {

    // TODO this slows everything down because the UI chugs right as you transition tab.
    // It's not as noticeable with actual networking rather than hitting localhost so it's probably fine for now.
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(props.entityConstants.reducer.fetchResources())
    }, [dispatch, props.entityConstants.reducer])

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const tableTheme = createTableTheme(theme, isMobile);

    const [selectedTableRows, setSelectedTableRows] = useState([] as any[])

    const AddButton = () => {
        return (
            <>
                <Button
                    component={Link}
                    to={`/${props.entityConstants.uri}/add`}
                    css={css`margin-left: 12px;`}
                    variant="contained"
                    color="primary"
                >
                    Add {props.entityConstants.name}
                </Button>
            </>
        )
    }

    const updateSearchParams = (key: string, value: string | null) => {
        const params = new URLSearchParams(window.location.search)

        if (value === null)
        {
            params.delete(key)
        } else {
            params.set(key, value)
        }

        navigate({search: `?${params.toString()}`})
    }

    const onChangePage = (pageNumber: number) => {
        updateSearchParams(URL_PARAM_PAGE, pageNumber.toString())
    };

    const onChangeRowsPerPage = (rowsPerPage: number) => {
        updateSearchParams(URL_PARAM_LIMIT, rowsPerPage.toString())
    };

    const onColumnSortChange = (changedColumn: string, direction: 'asc' | 'desc') => {
        updateSearchParams(URL_PARAM_SORT_BY, changedColumn);
        updateSearchParams(URL_PARAM_SORT_ORDER, direction);
    }

    const onSearchChange = (searchText: string | null) => {
        updateSearchParams(URL_PARAM_SEARCH, searchText);
    }

    const onRowClick = (rowData: string[]) => {
        navigate(`/${props.entityConstants.uri}/${rowData[0]}`)
    };

    const CustomToolbarSelect = (selectedRows: { data: Array<{ index: number; dataIndex: number }>; lookup: { [key: number]: boolean } }) => {
        return (
            <>
                {props.extraToolbarSelectComponents && props.extraToolbarSelectComponents.map(Component =>
                    <Component rows={selectedRows.data.map(selectedRow => data[selectedRow.dataIndex].id)}
                               entityConstants={props.entityConstants}/>)
                }
                <DeleteButton
                    rows={selectedRows.data.map(it => data[it.dataIndex])}
                    entityConstants={props.entityConstants}/>
            </>);
    }

    const tableOptions: MUIDataTableOptions = {
        textLabels: {
            pagination: {
                rowsPerPage: "Rows"
            }
        },
        print: false,
        download: false,
        enableNestedDataAccess: ".",
        pagination: true,
        elevation: 1,
        onChangePage: onChangePage,
        onChangeRowsPerPage: onChangeRowsPerPage,
        onColumnSortChange: onColumnSortChange,
        onSearchChange: onSearchChange,
        // @ts-ignore
        responsive: "vertical",
        rowsSelected: selectedTableRows,
        customToolbarSelect: CustomToolbarSelect,
        customToolbar: AddButton,
        onRowClick: onRowClick,
        ...props.tableOptions
    };

    // TODO usePreMount is being called twice. It does what I need here though.
    /**
     * Apply any URL parameters stored from changing the table state back onto the table.
     * This allows the full table state to be preserved when
     *  - Navigating back in the browser.
     *  - Linking the table to someone else.
     *  - Bookmarking a particular search.
     */
    usePreMount(() => {
        const urlParams = new URLSearchParams(window.location.search);

        const page = urlParams.get(URL_PARAM_PAGE);
        if (page) tableOptions.page = parseInt(page);

        const limit = urlParams.get(URL_PARAM_LIMIT);
        if (limit) tableOptions.rowsPerPage = parseInt(limit);

        const sortBy = urlParams.get(URL_PARAM_SORT_BY);
        const sortOrder = urlParams.get(URL_PARAM_SORT_ORDER);
        if (sortBy && sortOrder)
        {
            tableOptions.sortOrder = {
                name: sortBy,
                direction: sortOrder === "asc" ? "asc" : "desc"
            };
        }

        const search = urlParams.get(URL_PARAM_SEARCH);
        if (search) tableOptions.searchText = search;
    })

    const data = useMemo(() => {

        // Deselect everything any time the data backing the table changes.
        // Otherwise we could accidentally delete things we didn't want to.
        setSelectedTableRows([])

        return props.tableData.map(item => Object.assign({}, item))
    }, [props.tableData]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={tableTheme}>
                <MUIDataTable
                    columns={props.tableColumns}
                    options={tableOptions}
                    title={`${props.entityConstants.name} Table`}
                    data={data}
                />
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
