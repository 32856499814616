import {useSelector} from "react-redux";
import {RootState} from "../../state/rootReducer";
import React from "react";
import {MUIDataTableColumn} from "mui-datatables";
import {CrudTable} from "./CrudTable";
import {userConstants} from "../../constants/UserConstants";

export const UserTable = () => {

    const users = useSelector((state: RootState) => state.users);

    const tableColumns: MUIDataTableColumn[] = [
        {
            name: "id",
            options: {
                display: "excluded"
            }
        },
        {
            label: "Name",
            name: "name",
        },
        {
            label: "Email",
            name: "email"
        }
    ];

    return (
        <CrudTable
            tableColumns={tableColumns}
            tableData={users}
            entityConstants={userConstants}
        />
    )
};
