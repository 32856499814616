import {EditStringField} from "./EditStringField";
import {ViewStringField} from "./ViewStringField";
import {SingleField} from "./SingleField";
import React from "react";
import {SvgIconProps} from "@mui/material/SvgIcon/SvgIcon";
import {UseFormReturn} from "react-hook-form";

/**
 * Helper component for viewing / editing a string field.
 */
export const StringField: React.FC<{
    editMode: boolean
    form: UseFormReturn
    value?: string
    name: string
    title: string
    icon: (props: SvgIconProps) => JSX.Element
}> = (props) => {
    return (
        <SingleField editMode={props.editMode}
                     title={props.title}
                     icon={props.icon}
                     editComponent={
                         <EditStringField form={props.form} defaultValue={props.value} name={props.name}/>
                     }
                     viewComponent={
                         <ViewStringField text={props.value}/>
                     }/>
    )
}
