import {Box, IconButton} from "@mui/material";
import {css} from "@emotion/react";
import Tabs from "@mui/material/Tabs";
import {getActiveTab, hamburgerTabData, tabData, userTab} from "./TabData";
import Tab from "@mui/material/Tab";
import {Link, useLocation} from "react-router-dom";
import React, {useEffect} from "react";
import {toggleDarkMode, whoAmI} from "../../../state/reducers/settingsReducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../state/rootReducer";
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {serverUrl} from "../../../api/GemApi";

export const Sidebar: React.FC<{ close: () => void }> = (props) => {

    const currentRoute = useLocation();

    const dispatch = useDispatch<any>();
    const darkMode = useSelector((state: RootState) => state.settings.darkMode);
    const isAdmin = useSelector((state: RootState) => state.settings.isAdmin);

    useEffect(() => {
        dispatch(whoAmI())
    })

    let tabs = [...tabData, ...hamburgerTabData];

    if (isAdmin) {
        tabs = [...tabs, userTab]
    }

    const activeTab = getActiveTab(tabs, currentRoute);

    return (
        <Box css={css`width: 250px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between`} role="presentation">
            <Tabs
                css={css`width: 100%;`}
                TabIndicatorProps={{sx: {left: 0}}}
                indicatorColor="primary"
                orientation="vertical"
                centered={false}
                value={activeTab}
            >
                {tabs.map((tab, index) =>
                    <Tab
                        css={css`min-height: 3rem;
                          justify-content: start`}
                        key={tab.route}
                        component={Link}
                        onClick={props.close}
                        label={tab.text}
                        to={"/" + tab.route}
                        icon={<tab.icon css={css`margin-right: 2rem !important;`}/>}
                        iconPosition="start"
                    />
                )}
                <Tab
                    css={css`min-height: 3rem;
                          justify-content: start`}
                    key={"logout"}
                    onClick={() => {window.location.href = `${serverUrl}/logout`}}
                    label={"Log out"}
                    icon={<ExitToAppIcon css={css`margin-right: 2rem !important;`}/>}
                    iconPosition="start"
                />
            </Tabs>
            <IconButton
                css={css`width: 3rem;
                  align-self: center`}
                color="inherit"
                aria-label="Toggle Dark Mode"
                onClick={() => dispatch(toggleDarkMode())}
                size="large">
                {darkMode ? <Brightness7Icon/> : <Brightness4Icon/>}
            </IconButton>
        </Box>
    )
}

