import Typography from "@mui/material/Typography";
import React from "react";
import {css} from "@emotion/react";

/**
 * Helper component to view a string field.
 */
export const ViewStringField: React.FC<{text: string | null | undefined}> = (props) => {
     return (
         <Typography css={css`white-space: pre`} display="block" variant="body1">{props.text}</Typography>
     );
}
