import {ViewEntity} from "../Shared/ViewEntity";
import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../state/rootReducer";
import {gasTypeConstants} from "../../../constants/GasTypeConstants";

export const ViewGasType: React.FC = () => {

    const gasTypes = useSelector((state: RootState) => state.gasTypes);

    return (
        <ViewEntity
            entityArray={gasTypes}
            entityConstants={gasTypeConstants}
        />
    )
};
