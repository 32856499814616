import React, {ReactNode, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import {useForm} from "react-hook-form";
import {LoadingSpinner} from "./LoadingSpinner";
import {EntityFieldsGrid} from "./EntityFieldsGrid";
import {Entity, PreCreationEntity} from "../../../api/entityApis/Entity";
import {EntityConstants} from "../../../constants/EntityConstants";
import {SubmitButton} from "./SubmitButton";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import {PrintButton} from "../../Print/PrintButton";

/**
 * View / edit screen of any entity.
 */
//@ts-ignore
export const ViewEntity = <P extends PreCreationEntity, E extends Entity & P>(props: {

    /**
     * Everything we need about the entity we are viewing / editing.
     */
    entityConstants: EntityConstants<P, E>

    /**
     * Preloaded entity data from the API.
     * Used if possible on component render before the specific entity has been loaded.
     */
    entityArray: E[]

    children?: ReactNode
}) => {

    // TODO reset scroll on click to this page from the table - espesh on mobile

    const form = useForm();
    const dispatch = useDispatch<any>();

    // useParams is a react-router-dom hook to get URL parameters from any React component.
    const params = useParams<{ id: string }>();
    const id = parseInt(params.id ?? "-1");

    const entity = props.entityArray.find(entity => entity.id === id);

    // Re-grab the entity from the API in case it's changed (or we're going to this page straight from a URL).
    useEffect(() => {

        dispatch(props.entityConstants.reducer.fetchResources())
    }, [props.entityConstants.reducer, dispatch])

    const [editMode, setEditMode] = useState(false);

    const onSubmit = (data: any) => {
        const entity = props.entityConstants.convertFormToEntity(data)
        editEntity(entity);
        setEditMode(false);
    };

    const editEntity = (editedEntity: P) => {

        props.entityConstants.api.edit(id, editedEntity).then(entity => {
            dispatch(props.entityConstants.reducer.fetchResources());
            toast.success(`${props.entityConstants.name} ${id} edited successfully.`)
        }).catch(error => {
            toast.error(`Failed to edit ${props.entityConstants.name}: ${error.message}`)
        })
    }

    if (!entity) {
        return <LoadingSpinner/>
    }

    const title = `${editMode ? "Editing" : ""} ${props.entityConstants.name} ${entity.id}`

    return (
        <Card>
            <CardHeader title={title}
                        action={<>
                            <PrintButton entityConstants={props.entityConstants} rows={[entity.id]}></PrintButton>
                            <IconButton onClick={() => 
                                setEditMode(!editMode)} aria-label="edit" size="large"><EditIcon/></IconButton>
                        </>}/>
            <EntityFieldsGrid>
                <props.entityConstants.fields editMode={editMode} form={form} entity={entity}/>
                {editMode ? null : props.children}
                {editMode ?
                    <SubmitButton form={form} onSubmit={onSubmit}/> : null}
            </EntityFieldsGrid>
        </Card>
    );
};

// TODO skeleton if still waiting for API, otherwise NOT FOUND.
// <Container>
//     <Skeleton variant="text"/>
//     <Skeleton variant="circle" width={40} height={40}/>
//     <Skeleton variant="rect" width={210} height={118}/>
// </Container>

// TODO breadcrumbs?
// {/*<Breadcrumbs aria-label="breadcrumb">*/}
// {/*    <MaterialLink component={Link} color="inherit" to="/">*/}
// {/*        Home*/}
// {/*    </MaterialLink>*/}
// {/*    <MaterialLink component={Link} color="inherit" to="/regulators/">*/}
// {/*        Regulators*/}
// {/*    </MaterialLink>*/}
// {/*    <MaterialLink*/}
// {/*        component={Link}*/}
// {/*        color="textPrimary"*/}
// {/*        to={`/regulators/${id}/`}*/}
// {/*        aria-current="page"*/}
// {/*    >*/}
// {/*        Regulator #{id}*/}
// {/*    </MaterialLink>*/}
// {/*</Breadcrumbs>*/}

