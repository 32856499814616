import {PreCreationGasType} from "../../../api/entityApis/GasTypeApi";

export const convertFormToGasType = (data: any): PreCreationGasType => {

    console.debug(data);

    return {
        gasType: data.gasType,
        darkFont: data.darkFont,
        colour: data.colour
    }
};
