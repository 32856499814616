import {SvgIconProps} from "@mui/material/SvgIcon/SvgIcon";
import React from "react";
import {SingleFieldGrid} from "./SingleFieldGrid";

/**
 * Generic component used for all fields rendered on a single entity page.
 * Renders an icon, the title of the field, and the view/edit of the field.
 */
export const SingleField: React.FC<{

    /**
     * The title of the field.
     */
    title: string

    /**
     * Whether the form is in edit mode.
     */
    editMode: boolean

    /**
     * The icon to display.
     */
    icon: (props: SvgIconProps) => JSX.Element

    /**
     * The component to display whilst in edit mode.
     */
    editComponent: JSX.Element

    /**
     * The component to display whilst not in edit mode.
     */
    viewComponent: JSX.Element

}> = (props) => {

    return (
        <SingleFieldGrid icon={props.icon} title={props.title}>
            {props.editMode ? props.editComponent : props.viewComponent}
        </SingleFieldGrid>
    )
};
