import {CrudApi} from "../CrudApi";
import {Entity, PreCreationEntity} from "./Entity";

export const AuditHistoryApi = new CrudApi<AuditHistoryEntry, PreCreationAuditHistoryEntry>("audits")

/**
 * Interface of the regulator entity coming from the server. This should always match the fields in model.Regulator
 * on the server-side.
 */
export interface AuditHistoryEntry extends PreCreationAuditHistoryEntry, Entity {
    auditEndDate?: string
}

/**
 * This is the regulator before it has been created on the server side. as such it does not
 * yet have the unique ID generated by the server.
 */
export interface PreCreationAuditHistoryEntry extends PreCreationEntity
{
    locationId?: number
    locationCode?: string
    userId?: number
    userEmail?: string
    remainingRegulators: AuditedRegulator[]
    auditedRegulators: AuditedRegulator[]
    auditStartDate?: string
    success: boolean
}

export interface AuditedRegulator
{
    regulatorId?: number
    regulatorLocationId?: number
    regulatorLocationCode?: string
    id?: number
}
