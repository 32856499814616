import Grid from "@mui/material/Grid";
import {css} from "@emotion/react";
import React from "react";
import {SvgIconProps} from "@mui/material/SvgIcon/SvgIcon";
import Typography from "@mui/material/Typography";

export const SingleFieldGrid: React.FC<{
    title: string
    icon: (props: SvgIconProps) => JSX.Element
}> = (props) => {
    return (
        <Grid item xs={12} sm={6}>
            <Grid container spacing={1} css={css`flex-wrap: nowrap; padding-right: 28px;`}>
                <Grid item>
                    <props.icon fontSize="small" css={css`margin-top: 6px`}/>
                </Grid>
                <Grid item css={css`flex-grow: 1`}>
                    <Typography variant="h6">{props.title}</Typography>
                    {props.children}
                </Grid>
            </Grid>
        </Grid>
    )
}
