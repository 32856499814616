import ReactDOM from 'react-dom';
import './index.css';
import './print.css';
import * as serviceWorker from './serviceWorker';
import {Entrypoint} from './gem/components/App/Entrypoint';
import {Provider} from "react-redux";
import store, {persistor} from "./gem/state/store";
import {PersistGate} from 'redux-persist/integration/react'
import 'typeface-roboto';

const render = () => {

    ReactDOM.render(
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Entrypoint/>
            </PersistGate>
        </Provider>,
        document.getElementById('root')
    );
};

// if (process.env.NODE_ENV === 'development' && module.hot) {
//     whyDidYouRender(React, {
//         trackHooks: true,
//         trackAllPureComponents: true
//     });
//     module.hot.accept('./gem/components/App/App', render);
// }

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
