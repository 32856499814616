import {CrudReducer} from "./crudReducer";
import {Regulator, RegulatorApi} from "../../api/entityApis/RegulatorApi";

class RegulatorReducer extends CrudReducer<Regulator>
{
    getApi() {
        return RegulatorApi;
    }

    getName() {
        return "regulator";
    }

}

export const regulatorReducer = new RegulatorReducer();
