import {Link, useLocation} from "react-router-dom";
import React from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {getActiveTab, tabData} from "./TabData";
import {css} from "@emotion/react";

/**
 * Component used to provide navigation buttons to the user.
 * If the user is on a mobile-sized device, the tabs will be rendered with icons rather than text.
 */
export const HeaderTabs = () => {

    const currentRoute = useLocation();
    const activeTab = getActiveTab(tabData, currentRoute);

    return (
        <Tabs indicatorColor="primary" centered value={activeTab} >
            {tabData.map((tab, index) =>
                <Tab
                    css={css`min-width: 160px`}
                    key={tab.route}
                    component={Link}
                    label={tab.text}
                    to={"/" + tab.route}
                />
            )}
        </Tabs>
    )
};
