import {GasType} from "../../../api/entityApis/GasTypeApi";
import {css} from "@emotion/react";
import React from "react";
import {GasTypeChip} from "./GasTypeChip";
import {Chip} from "@mui/material";

export const GasTypeChips = (props: { gasTypes: GasType[], maxChips: number }) => {

    return (
        <>
            {props.gasTypes.length === 0 ?
                <Chip css={css`background-color: unset;`}/> : null
            }

            {props.gasTypes.map((gasType, index) => {

                    if (index < props.maxChips) {
                        return (<GasTypeChip key={gasType.id} gasType={gasType}/>)
                    } else if (index === props.maxChips) {
                        return (
                            <span key="ADDITIONAL_CHIPS" css={css`margin-left: 3px; margin-right: 3px;`}>
                            +{props.gasTypes.length - props.maxChips}
                        </span>
                        );
                    } else {
                        return null;
                    }
                }
            )}
        </>
    );
}
