import {EntityConstants} from "./EntityConstants";
import {GemUser, PreCreationGemUser, UserApi} from "../api/entityApis/UserApi";
import {convertFormToUser} from "../components/SingleEntity/User/convertFormToUser";
import {userReducer} from "../state/reducers/userReducer";
import {UserFields} from "../components/SingleEntity/User/UserFields";

export const userConstants: EntityConstants<PreCreationGemUser, GemUser> = {
    name: "User",
    uri: "users",
    api: UserApi,
    convertFormToEntity: convertFormToUser,
    reducer: userReducer,
    fields: UserFields,
    getData: (state => state.users)
}
