import {useSelector} from "react-redux";
import {RootState} from "../../state/rootReducer";
import React from "react";
import {MUIDataTableColumn} from "mui-datatables";
import {CrudTable} from "./CrudTable";
import {gasTypeConstants} from "../../constants/GasTypeConstants";
import {GasTypeChip} from "../SingleEntity/GasType/GasTypeChip";

export const GasTypeTable = () => {

    const gasTypes = useSelector((state: RootState) => state.gasTypes);

    const tableColumns: MUIDataTableColumn[] = [
        {
            name: "id",
            options: {
                display: "excluded"
            }
        },
        {
            label: "Gas Type",
            name: "gasType",
            options: {
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return <GasTypeChip gasType={gasTypes[dataIndex]}/>
                }
            }
        }
    ];

    return (
        <CrudTable
            tableColumns={tableColumns}
            tableData={gasTypes}
            entityConstants={gasTypeConstants}
        />
    )
};
