import {css} from "@emotion/react";
import ImageIcon from "@mui/icons-material/Image";
import {ImageApi} from "../../../api/ImageApi";
import Button from "@mui/material/Button/Button";
import React, {useMemo, useState} from "react";
import {Box, DialogContent, DialogActions} from "@mui/material";
import {Skeleton} from '@mui/material';
import {SingleFieldGrid} from "../Shared/SingleField/SingleFieldGrid";
import {serverUrl} from "../../../api/GemApi";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import imageCompression from 'browser-image-compression'
import {toast} from "react-toastify";


enum LoadingState {
    LOADING,
    ERROR,
    LOADED
}

export const RegulatorImage = (props: { id: number, editMode: boolean }) => {

    const [key, setKey] = useState(0);
    const [clearDialogOpen, setClearDialogOpen] = useState(false);

    const api = new ImageApi();

    // NOTE that the image URL used twice in this file can't be pulled out to here,
    // as it causes the component to re-render where it shouldn't.

    // TODO swap to using the useImage() hook rather than the legacy <Img> component.
    // TODO This will allow checking whether the image has loaded or if it is even present - which will let us
    // TODO only make the image a link when we know it's a valid image.

    const Image = (props: { myKey: number, id: number }) => {

        const [loadingState, setLoadingState] = useState(LoadingState.LOADING)

        return <>
            {
                loadingState === LoadingState.LOADING ?
                    <Skeleton height={300} variant="rectangular"/> : null
            }
            {
                loadingState === LoadingState.ERROR ?
                    <Skeleton height={300} animation={false} variant="rectangular"/> : null
            }
            <a href={`${serverUrl}/v1/images/regulator/${props.id}?key=${props.myKey}`}>
                <img
                    css={css`max-width: 100%;
                      max-height: 500px;
                      display: ${loadingState === LoadingState.LOADED ? "unset" : "none"}`}
                    src={`${serverUrl}/v1/images/regulator/${props.id}/700?key=${props.myKey}`}
                    alt="Regulator"
                    onLoad={() => setLoadingState(LoadingState.LOADED)}
                    onError={() => setLoadingState(LoadingState.ERROR)}
                />
            </a>
        </>;
    }

    /**
     * Create a "memo" of the image component.
     * A memo'd component will only re-render if its specific dependencies change.
     */
    const memoImage = useMemo(() => {
        return <Image key={key} myKey={key} id={props.id}/>
    }, [key, props.id])

    const openClearImageDialog = () => setClearDialogOpen(true);

    const closeClearImageDialog = () => setClearDialogOpen(false);

    const clearImage = () => {
        api.clearImage(props.id.toString()).then(() => {
            setKey(key + 1);
        });
        closeClearImageDialog()
    };

    return (
        <SingleFieldGrid title="Image" icon={ImageIcon}>
            <Box css={css`max-width: 100%;
              min-height: 300px;
              max-height: 500px;`}>
                {memoImage}
            </Box>
            {
                props.editMode ?
                    <>
                        <input
                            key={key}
                            css={css`display: none`}
                            accept="image/*"
                            type="file"
                            id="contained-button-file"
                            onChange={async (e) => {

                                let imageToCompress = e.target?.files?.[0];
                                if (imageToCompress) {
                                    try {
                                        imageToCompress = await (imageCompression(imageToCompress, {
                                            maxWidthOrHeight: 4032,
                                            maxSizeMB: 0.5,
                                        }))
                                    } catch (error) {
                                        console.log(error)
                                    }

                                    api.putImage(props.id.toString(), imageToCompress).then(_ => {
                                        setKey(key + 1);
                                    })
                                } else {
                                    toast.error("Error: No valid image to upload could be found")
                                }
                            }}
                        />
                        <label htmlFor="contained-button-file">
                            <Button variant="contained" color="primary" component="span"
                                    css={css`margin-right: 0.5rem;
                                      margin-top: 0.5rem`}>
                                Upload image
                            </Button>
                        </label>
                        <Button css={css`margin-top: 0.5rem`} variant="contained" color="secondary"
                                onClick={openClearImageDialog}>
                            Clear image
                        </Button>
                        <Dialog open={clearDialogOpen} onClose={closeClearImageDialog}>
                            <DialogTitle>Clear image?</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Clearing the image will delete it permanently.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeClearImageDialog}>Cancel</Button>
                                <Button onClick={clearImage}>Clear</Button>
                            </DialogActions>
                        </Dialog>
                    </>
                    : null
            }
        </SingleFieldGrid>
    );
}
