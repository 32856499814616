import React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../state/rootReducer";
import App from "./App";
import {createTheme, ThemeProvider, StyledEngineProvider} from '@mui/material/styles';
import {deepOrange, teal} from '@mui/material/colors';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from "@mui/x-date-pickers";
import CssBaseline from "@mui/material/CssBaseline";
import {Slide, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


export const Entrypoint = () => {

    const settings = useSelector((state: RootState) => state.settings);

    // We can use this to auto-select dark mode on mobile. Currently overrides the button though so needs work.
    // const dispatch = useDispatch();
    // const prefersDarkMode = useMediaQuery({query: '(prefers-color-scheme: dark)'});
    // dispatch(setDarkMode(prefersDarkMode));

    // Choosing a colour scheme:
    // https://material.io/resources/color/#!/?view.left=0&view.right=0&secondary.color=FF9E80&primary.color=546E7A
    const muiTheme = createTheme({
        components: {
            MuiButtonBase: {
                defaultProps: {
                    disableRipple: false
                }
            }
        },
        palette: {
            primary: teal,
            secondary: deepOrange,
            mode: settings.darkMode ? "dark" : "light",
            success: teal
        }
    });

    return (
        <Router>
            <StyledEngineProvider injectFirst>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <ThemeProvider theme={muiTheme}>
                        <CssBaseline/>
                        <App/>
                        <ToastContainer
                            position="bottom-center"
                            autoClose={5000}
                            hideProgressBar
                            newestOnTop
                            closeOnClick
                            pauseOnFocusLoss
                            pauseOnHover
                            transition={Slide}
                        />
                    </ThemeProvider>
                </LocalizationProvider>
            </StyledEngineProvider>
        </Router>
    );
};
