import {EntityConstants} from "./EntityConstants";
import {GasType, GasTypeApi, PreCreationGasType} from "../api/entityApis/GasTypeApi";
import {convertFormToGasType} from "../components/SingleEntity/GasType/convertFormToGasType";
import {GasTypeFields} from "../components/SingleEntity/GasType/GasTypeFields";
import {gasTypeReducer} from "../state/reducers/gasTypeReducer";

export const gasTypeConstants: EntityConstants<PreCreationGasType, GasType> = {
    name: "Gas Type",
    uri: "gasTypes",
    api: GasTypeApi,
    convertFormToEntity: convertFormToGasType,
    reducer: gasTypeReducer,
    fields: GasTypeFields,
    getData: (state => state.gasTypes)
}
