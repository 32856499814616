import {createSlice} from "@reduxjs/toolkit";
import {AuditHistoryApi, PreCreationAuditHistoryEntry} from "../../../api/entityApis/AuditHistoryApi";
import {auditRegulator, resetAudit, startAudit} from "./auditActions";
import {AppThunk} from "../../store";
import {toast} from "react-toastify";

export interface AuditState {
    currentAudit?: PreCreationAuditHistoryEntry,
    auditMode: boolean
}

const initialState: AuditState = {
    auditMode: false
};

const auditSlice = createSlice({
    name: "audit",
    initialState: initialState,
    reducers: {
        startAudit: startAudit,
        auditRegulator: auditRegulator,
        resetAudit: resetAudit
    }
});

/**
 * Async action to complete an audit, posting the results to the server.
 */
export const completeAudit = (state: AuditState): AppThunk => async dispatch => {

    if (!state.currentAudit)
    {
        throw Error("Invalid state. Regulator has been audited when audit mode is disabled.")
    }

    let audit = {...state.currentAudit}

    if (state.currentAudit.remainingRegulators.length === 0 && state.currentAudit.success)
    {
        toast.success(`Location ${state.currentAudit.locationCode} has been audited successfully.`)
    } else {
        audit.success = false
        toast.error(`Location ${state.currentAudit.locationCode} has been audited with errors.`)
    }

    await AuditHistoryApi.create(audit)
    dispatch(serverAuditActions.resetAudit())
}

export const serverAuditActions = auditSlice.actions;
export const auditReducer = auditSlice.reducer;
