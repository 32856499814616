import {CrudReducer} from "./crudReducer";
import {AuditHistoryEntry, AuditHistoryApi} from "../../api/entityApis/AuditHistoryApi";

class AuditHistoryReducer extends CrudReducer<AuditHistoryEntry>
{
    getApi() {
        return AuditHistoryApi;
    }

    getName() {
        return "auditHistory";
    }
}

export const auditHistoryReducer = new AuditHistoryReducer();
