import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {EntityFieldsGrid} from "./EntityFieldsGrid";
import {Entity, PreCreationEntity} from "../../../api/entityApis/Entity";
import {EntityConstants} from "../../../constants/EntityConstants";
import {SubmitButton} from "./SubmitButton";
import {toast} from "react-toastify";

/**
 * New screen of any entity.
 */
export const NewEntity = <P extends PreCreationEntity, E extends Entity & P>(props: {

    /**
     * Everything we need about the entity we are creating.
     */
    entityConstants: EntityConstants<P, E>,

    /**
     * The initial entity to start from.
     */
    initialEntity: P
}) => {

    const form = useForm();
    const navigate = useNavigate();

    const onSubmit = (data: any) => {

        const entity = props.entityConstants.convertFormToEntity(data)
        postEntity(entity);
    };

    const postEntity = (entity: P) => {
        props.entityConstants.api.create(entity).then((entity) => {

            toast.success(`${props.entityConstants.name} ${entity.id} added.`)
            navigate(`/${props.entityConstants.uri}/${entity.id}`)

        }).catch(error => {

            toast.error(`Failed to create ${props.entityConstants.name}: ${error.message}`)
        });
    };

    return (
        <Card>
            <CardHeader title={`Create ${props.entityConstants.name}`}/>
            <EntityFieldsGrid>
                <props.entityConstants.fields editMode={true} form={form} entity={props.initialEntity}/>
                <SubmitButton form={form} onSubmit={onSubmit}/>
            </EntityFieldsGrid>
        </Card>
    );
};
