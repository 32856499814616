import React from "react";
import {Button} from "@mui/material";
import NewWindow from "react-new-window"
import {css} from "@emotion/react";
import {PrintWindow} from "./PrintWindow";
import {EntityConstants} from "../../constants/EntityConstants";

export const labelsPerPage: number = 35;

export const PrintButton: React.FC<{
    rows: number[],
    entityConstants: EntityConstants<any, any>
}> = (props) => {


    const [printWindowOpen, setPrintWindowOpen] = React.useState(false);


    return <>
        <Button
            variant="contained"
            color="primary"
            css={css`margin-right: 26px;`}
            onClick={() => setPrintWindowOpen(true)}
        >
            Print QR Code(s)
        </Button>

        {printWindowOpen ?
            <NewWindow
                copyStyles={true}
                title="Print QR Code(s)"
                onUnload={() => setPrintWindowOpen(false)}
            >
                <PrintWindow rows={props.rows} emptyLabels={0} entityConstants={props.entityConstants}/>
            </NewWindow> : null
            }
    </>;


}


