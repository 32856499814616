import {EntityConstants} from "./EntityConstants";
import {GemLocation, LocationApi, PreCreationGemLocation} from "../api/entityApis/LocationApi";
import {locationReducer} from "../state/reducers/locationReducer";
import {convertFormToLocation} from "../components/SingleEntity/Location/convertFormToLocation";
import {LocationFields} from "../components/SingleEntity/Location/LocationFields";

export const locationConstants: EntityConstants<PreCreationGemLocation, GemLocation> = {
    name: "Location",
    uri: "locations",
    api: LocationApi,
    convertFormToEntity: convertFormToLocation,
    reducer: locationReducer,
    fields: LocationFields,
    getData: (state => state.locations)
}
