import React from "react";
import {ISingleEntityFields} from "../Shared/ISingleEntityFields";
import {StringField} from "../Shared/SingleField/StringField";
import {PreCreationGasType} from "../../../api/entityApis/GasTypeApi";
import {SingleField} from "../Shared/SingleField/SingleField";
import Switch from "@mui/material/Switch/Switch";
import {Controller} from "react-hook-form";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import PaletteIcon from '@mui/icons-material/Palette';
import Brightness3Icon from '@mui/icons-material/Brightness3';

export const GasTypeFields: React.FC<ISingleEntityFields<PreCreationGasType>> = (props) => {
    return (
        <>
            <StringField
                editMode={props.editMode}
                form={props.form}
                value={props.entity.gasType}
                title="Gas Type"
                name="gasType"
                icon={AvTimerIcon}
            />
            <StringField
                editMode={props.editMode}
                form={props.form}
                value={props.entity.colour}
                title="Colour"
                name="colour"
                icon={PaletteIcon}
            />
            <SingleField editMode={props.editMode}
                         title={"Dark Font"}
                         icon={Brightness3Icon}
                         editComponent={
                             // TODO show the colour as its changing - colour picker?
                             <Controller
                                 name="darkFont"
                                 control={props.form.control}
                                 defaultValue={props.entity.darkFont}
                                 render={({field}) => <Switch defaultChecked={props.entity.darkFont} {...field}/>}
                             />
                         }
                         viewComponent={
                             <Switch checked={props.entity.darkFont} disabled name="darkFont"/>
                         }/>
        </>
    )
};
