import {CrudApi} from "../CrudApi";
import {Entity, PreCreationEntity} from "./Entity";

export const LocationApi = new CrudApi<GemLocation, PreCreationGemLocation>("locations")

/**
 * Interface of the regulator entity coming from the server. This should always match the fields in model.Regulator
 * on the server-side.
 */
export interface GemLocation extends PreCreationGemLocation, Entity {
}

/**
 * This is the regulator before it has been created on the server side. as such it does not
 * yet have the unique ID generated by the server.
 */
export interface PreCreationGemLocation extends PreCreationEntity
{
    locationCode?: string
}
