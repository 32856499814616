import {combineReducers} from "@reduxjs/toolkit";
import {settingsReducer} from "./reducers/settingsReducer";
import {regulatorReducer} from "./reducers/regulatorReducer";
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {userReducer} from "./reducers/userReducer";
import {locationReducer} from "./reducers/locationReducer";
import {gasTypeReducer} from "./reducers/gasTypeReducer";
import {auditHistoryReducer} from "./reducers/auditHistoryReducer";
import {auditReducer} from "./reducers/serverAudit/auditReducer";

const rootReducer = combineReducers({
    regulators: regulatorReducer.reducer,
    users: userReducer.reducer,
    locations: locationReducer.reducer,
    gasTypes: gasTypeReducer.reducer,
    auditHistory: auditHistoryReducer.reducer,
    settings: settingsReducer,
    audit: auditReducer
});

const persistConfig = {
    key: "redux",
    storage: storage,
    // Reducers that we want persisted - based on the keys above.
    whitelist: ["audit", "settings"],
    // Reducers we don't want to persist.
    blacklist: [],
};

const perisistedRootReducer = persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>

export default perisistedRootReducer
