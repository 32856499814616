import {
    Container,
    Grid,
    Paper,
    Typography
} from "@mui/material";
import React, {useEffect, useRef} from "react";
import {
    XAxis,
    YAxis,
    ResponsiveContainer,
    BarChart,
    PieChart,
    Pie,
    Cell,
    Tooltip,
    CartesianGrid,
    Bar,
    Legend
} from 'recharts';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../state/rootReducer";
import {regulatorConstants} from "../../constants/RegulatorConstants";
import add from 'date-fns/add'
import differenceInMonths from 'date-fns/differenceInMonths'
import format from "date-fns/format";
import {parse} from "date-fns";
import {ServicesDueTable} from "../Table/servicesDueTable";



export const HomePage = () => {

    const dispatch = useDispatch<any>();
    const chartContainer = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        dispatch(regulatorConstants.reducer.fetchResources())
    }, [dispatch])

    const regulators = useSelector((state: RootState) => state.regulators);

    let numberInService = 0;
    let numberOutOfService = 0;

    //Count up the number of regulators that are in service.
    regulators.forEach(function (regulator) {

        if (regulator.inService) {
            numberInService += 1;
        } else {
            numberOutOfService += 1;
        }

    });

    const inServiceData = [
        {"name": "In Service", "value": numberInService},
        {"name": "Out of Service", "value": numberOutOfService}
    ];

    const colours = ["#009688", "#CCCCCC"]

    let serviceDateCounts: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    regulators.forEach(function (regulator) {

            if (regulator.serviceDueDate) {
                let diff = differenceInMonths(parse(regulator.serviceDueDate, "yyyy-MM-dd", 0), new Date())
                serviceDateCounts[diff] += 1;
            }

    });

    //Get the next 12 month names from today's date.
    let names: string[] = [];
    for (let i = 0; i < 12; i++) {
        names[i] = format(add(new Date(), {months: i}), 'MMM')
    }
    // Make chart data.
    const serviceDateData = names.map((name, index) =>
        ({
            "name": name,
            "Services Due": serviceDateCounts[index]
        })
    )

    return (
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12} md={8} lg={9}>
                    <Paper
                        ref={chartContainer}
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 240,
                        }}
                    >
                        <BarChart width={chartContainer.current ? chartContainer.current.offsetWidth / 1.15 : 600}
                                  height={230} data={serviceDateData}>

                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="name"/>
                            <YAxis/>
                            <Tooltip/>

                            <Bar dataKey="Services Due" fill={colours[0]}/>

                        </BarChart>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 240,
                        }}
                    >

                        {/* Ratio of In Service to Out of Service */}
                        <Typography>In Service Regulators</Typography>
                        <ResponsiveContainer>
                            <PieChart margin={{
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                            }}>
                                <Tooltip/>
                                <Pie data={inServiceData} dataKey="value" nameKey="name" cx="50%" cy="50%"
                                     outerRadius={60} fill="#009688" label>
                                    {
                                        inServiceData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={colours[index]}/>
                                        ))
                                    }
                                </Pie>
                                <Legend/>

                            </PieChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <ServicesDueTable/>
                </Grid>
            </Grid>
        </Container>
    )
}