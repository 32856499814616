import {PreCreationServiceHistoryEntry, ServiceHistoryEntry} from "../../../api/entityApis/ServiceHistoryEntryApi";
import React, {useEffect, useState} from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/Add';
import {
    Collapse,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    IconButton,
    Box,
    Paper, TableBody, Modal
} from "@mui/material";
import format from "date-fns/format";
import {parse} from "date-fns";
import DeleteIcon from '@mui/icons-material/Delete';
import add from 'date-fns/add'
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button/Button";
import Grid from "@mui/material/Grid";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    outline:0,
};

const textFieldStyle = {
    width: '95%',
    margin: '2.5%',
}

export const ServiceHistoryTable = (props: { id: number | undefined, editMode: boolean, serviceHistoryEntries: ServiceHistoryEntry[] | undefined, ServiceHistoryCallbackFunction: any }) => {

    const [entries, updateEntries] = useState<ServiceHistoryEntry[]>([]);
    const [modalOpen, setModalOpen] = React.useState(false);


    function handleClose() {

        //This function runs when the modal closes.

        setModalOpen(false);

    }

    useEffect(() => {
        /* Load the current service history entries in before replacing them during editing. */
        if (entries.length < 1) {
            if (props.serviceHistoryEntries) {
                updateEntries(props.serviceHistoryEntries);
            }
            //console.log(entries);
        }
    }, [entries.length, props.serviceHistoryEntries])

    function ModifyEntry(id: number, entryText: string) {
        
        /*first, find the entry by id*/

        let entryToUpdate = entries.find(element => element.id === id)

        /* next, modify the entry text */
        if (entryToUpdate) {

            let entryToUpdateIndex = entries.findIndex(element => element.id === id)


            // Logic to differentiate between entry text and a service due date


            entryToUpdate.entryText = entryText;


            // now just slap it in by copying the array?

            let entriesArray = entries;

            entriesArray[entryToUpdateIndex] = entryToUpdate;   //Replaced the service history entry at the new index
            updateEntries(entriesArray) //now modify the state array

            // This calls the function which updates the form controller with the new entries array.
            props.ServiceHistoryCallbackFunction(entries);

        }

    }


    const tableArrowStyle = {
        width: '6%'
    }

    function DeleteEntry(id: number) {


        // copy the array

        let entriesArray = entries.slice();
        let entryToUpdateIndex = entries.findIndex(element => element.id === id);

        entriesArray.splice(entryToUpdateIndex, 1)

        updateEntries(entriesArray);


    }

    function AddButtonClicked() {
        // opens the modal encapsulating this bitch

        setModalOpen(true);

    }

    function AddEntry(entryText:string, entryDate:string) {

        let id = entries.length;

        // This is where the fun begins
        // I throw up a Modal which gets the user to configurate this shit
        // I might make a function which calls this function.

        let newEntry: ServiceHistoryEntry = {
            entryDate: entryDate,
            entryText: entryText,
            id: id,
        }

        updateEntries([...entries, newEntry]);
    }

    function Row(props: { row: ServiceHistoryEntry | PreCreationServiceHistoryEntry, editMode: boolean }) {
        const row = props.row;
        const editMode = props.editMode;
        const [open, setOpen] = React.useState(false);
        const [value, setValue] = React.useState(row.entryText);

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

            setValue(event.target.value);
            ModifyEntry(row.id, value);

        };

        return (
            <React.Fragment>
                <TableRow style={tableStyle}>
                    <TableCell style={tableArrowStyle}>
                        <IconButton onClick={() => setOpen(!open)} aria-label="expand row" size="small">
                            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        </IconButton>
                    </TableCell>
                    <TableCell align="left">{format(parse(row.entryDate, 'yyyy-MM-dd', 0), 'dd/MM/yyyy')}</TableCell>
                    <TableCell align="right">
                        {props.editMode ?
                            <></> :
                            <IconButton onClick={() => DeleteEntry(row.id)} aria-label="delete" size="small">
                                <DeleteIcon></DeleteIcon>
                            </IconButton>

                        }
                    </TableCell>
                </TableRow>
                <TableRow style={tableStyle}>
                    <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Table size="small" aria-label="purchases">
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <TextField
                                                style={textFieldStyle}
                                                id="standard-multiline-flexible"
                                                label="Service History Entry"
                                                value={value}
                                                disabled={editMode}
                                                multiline={true}
                                                onChange={handleChange}
                                            />
                                        </TableCell>

                                    </TableRow>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );

    }

    const tableStyle = {
        paddingLeft: '30px', paddingRight: '10px',
    }

    let defaultDate = String(format(add(new Date(), {months: 12}), 'yyyy-MM-dd'))

    const [newEntryText, modifyNewEntryText] = React.useState("");
    const [newEntryDate, modifyNewEntryDate] = React.useState(defaultDate);

    const newEntryTextHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        modifyNewEntryText(event.target.value)

    };

    const newEntryDateHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        modifyNewEntryDate(event.target.value)

    };

    // @ts-ignore
    return (
        <>
        <Modal
        open={modalOpen}
        onClose={handleClose}
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    New Service History Entry
                </Typography>

                <TextField
                    style={textFieldStyle}
                    id="standard-multiline-flexible"
                    value={newEntryText}
                    multiline={true}
                    onChange={newEntryTextHandleChange}
                />

                <TextField
                    style={textFieldStyle}
                    id="service-history-date-picker"
                    value={newEntryDate}
                    type="date"
                    onChange={newEntryDateHandleChange}

                    />



                <Grid item xs={12}>
                        <Box m={2}>
                            <Button onClick={()=> {
                                AddEntry(newEntryText, newEntryDate); handleClose();
                                modifyNewEntryText(""); modifyNewEntryDate(defaultDate)}}
                                    type="submit" variant="contained" color="primary">
                                Submit
                            </Button>
                        </Box>
                </Grid>


            </Box>
        </Modal>

        <TableContainer component={Paper}>
            <Table aria-label="service-history-table">
                <TableHead>
                    <TableRow>
                        <TableCell>Service History</TableCell>
                        <TableCell style={tableArrowStyle}>
                            {props.editMode ?
                                <IconButton onClick={() => AddButtonClicked()} aria-label="add button" size="small">
                                    <AddIcon></AddIcon>
                                </IconButton>
                                : <></>
                            }
                        </TableCell>
                    </TableRow>
                </TableHead>
            </Table>
            <Table>
                <TableBody>
                    {entries?.map((row) => (
                        <Row row={row} editMode={!props.editMode}/>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>


        </>
    );

}

