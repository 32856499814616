import {PreCreationRegulator} from "../../../api/entityApis/RegulatorApi";
import {NewEntity} from "../Shared/NewEntity";
import React from "react";
import {regulatorConstants} from "../../../constants/RegulatorConstants";

export const NewRegulator = () => {

    const initialRegulator: PreCreationRegulator = {
        inService: true,
        type: "REGULATOR",
        modified: "No",
        gasTypes: []
    }

    return (
        <NewEntity
            initialEntity={initialRegulator}
            entityConstants={regulatorConstants}
        />
    );
}
