import React from "react";
import {PreCreationGemUser} from "../../../api/entityApis/UserApi";
import {NewEntity} from "../Shared/NewEntity";
import {userConstants} from "../../../constants/UserConstants";

export const NewUser: React.FC = () => {

    const initialUser: PreCreationGemUser = {
        email: "",
        name: "",
        authorities: [],
        shouldReceiveEmail: false
    }

    return (
        <NewEntity
            initialEntity={initialUser}
            entityConstants={userConstants}
        />
    )
};
