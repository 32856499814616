import {css} from "@emotion/react";
import React, {useState} from "react";
import sizeMe from "react-sizeme";
import AppBar from "@mui/material/AppBar/AppBar";
import {Link, useLocation} from "react-router-dom";
import {getActiveTab, tabData} from "./TabData";
import {Tab, Tabs, Typography} from "@mui/material";

/**
 * Footer that sticks to the bottom of the app. Should only be displayed on mobile-sized displays.
 */
export const FooterTabs = () => {

    // The height of the inner footer is tracked so that an equivalent amount of height can be blocked at the
    // bottom of the page using a div. This is because the footer is `position="fixed"` and so doesn't obstruct content.
    const [height, setHeight] = useState(0);

    return (
        <header>
            <div style={{height: height}}/>
            <AppBar color="default" position="fixed" css={css`bottom: 0; top: unset;`}>
                <SizedFooter onSize={(size) => setHeight(size.height || 0)}/>
            </AppBar>
        </header>
    )
};

/**
 * Footer component whose height is automatically calculated by react-sizeme.
 */
const SizedFooter = sizeMe({monitorHeight: true, monitorWidth: false})(() => {

    const currentRoute = useLocation();
    const activeTab = getActiveTab(tabData, currentRoute);

    return (
        // env(safe-area-inset-bottom) is used to calculate additional padding needed at the bottom of the page.
        // This is used to detect the iPhone X home bar.
        <div>
            <Tabs
                indicatorColor="primary"
                variant="fullWidth"
                value={activeTab}
                css={css`padding-bottom: env(safe-area-inset-bottom)`}
            >
                {tabData.map((tab) =>
                    <Tab
                        css={css`padding-top: 6px; padding-bottom: 6px; min-height: unset`}
                        component={Link}
                        to={"/" + tab.route}
                        key={tab.route}
                        label={<Typography variant="caption">{tab.text}</Typography>}
                        icon={<tab.icon css={css`margin-bottom: 4px !important;`}/>}
                    />
                )}
            </Tabs>
        </div>
    )
});
