import React from "react";
import {NewEntity} from "../Shared/NewEntity";
import {PreCreationGasType} from "../../../api/entityApis/GasTypeApi";
import {gasTypeConstants} from "../../../constants/GasTypeConstants";

export const NewGasType: React.FC = () => {

    const initialGasType: PreCreationGasType = {
        gasType: ""
    }

    return (
        <NewEntity
            initialEntity={initialGasType}
            entityConstants={gasTypeConstants}
        />
    )
};
