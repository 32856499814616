import {format} from "date-fns";
import {PreCreationRegulator} from "../../../api/entityApis/RegulatorApi";

export const convertFormToRegulator = (data: any): PreCreationRegulator => {

    //console.debug(data);

    //Converts in service indicator to boolean, as the data is required to be in boolean form.
    const inService = data.inService === "Yes";

    // TODO handle the date being incorrect
    // TODO handle the date being empty and set it to null.

    const modifyDate = (date: Date) => {
        if (date) {
            return format(date, "yyyy-MM-dd");
        }
        return "";
    }

    return {
        name: data.serialNum,
        description: data.description,
        gasTypes: data.gasTypes,
        inService: inService,
        location: {id: data.location},
        manufacturer: data.manufacturer,
        manufacturerPartNum: data.manufacturerPartNum,
        modified: data.modified,
        serialNum: data.serialNum,
        manufactureDate: modifyDate(data.manufactureDate),
        serviceDueDate: modifyDate(data.serviceDueDate),
        type: data.type,
        maxPressure: data.maxPressure,
        serviceHistoryEntries:data.serviceHistoryEntries,
    };
};