import React from "react";
import {css} from "@emotion/react";

export const QRLabelText: React.FC<{
    name: string;
    data: any;
}> = (props) => {

    if (props.name === "Location") {

        return <p css={css`font-size: 0.875rem;`}>{props.data.locationCode}</p>
    } else if (props.name === "Regulator") {
        return <div css={css`min-width:200px;transform-origin:bottom left;transform:rotate(90deg) translateX(-60px);`}>
            <p css={css`font-size: 0.875rem;`}>{props.data.type} {props.data.id}</p>
            <p css={css`font-size: 0.875rem;`}>{props.data.location?.locationCode}</p>

            </div>
    } else {
        return <p/>
    }
}
