import {useSelector} from "react-redux";
import {RootState} from "../../state/rootReducer";
import React from "react";
import {MUIDataTableColumn} from "mui-datatables";
import {CrudTable} from "./CrudTable";
import {auditHistoryConstants} from "../../constants/AuditHistoryConstants";

export const AuditHistoryTable = () => {

    const auditHistory = useSelector((state: RootState) => state.auditHistory);

    const tableColumns: MUIDataTableColumn[] = [
        {
            name: "id",
            options: {
                display: "excluded"
            }
        },
        {
            label: "Location",
            name: "locationCode"
        },
        {
            label: "Success",
            name: "success",
            options: {
                customBodyRenderLite: dataIndex => auditHistory[dataIndex].success ? "True" : "False"
            }
        },
        {
            label: "Date",
            name: "auditEndDate"
        }
    ];

    return (
        <CrudTable
            tableColumns={tableColumns}
            tableData={auditHistory}
            tableOptions={{
                customToolbar: () => React.Fragment
            }}
            entityConstants={auditHistoryConstants}
        />
    )
};
