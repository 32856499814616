import {GemApi, serverUrl} from "./GemApi";

export class ImageApi extends GemApi {

    public async putImage(regId: string, body: any): Promise<Response>
    {
        const formData = new FormData()
        formData.append("file", body)

        const config: RequestInit = {
            credentials: "include",
            method: "PUT",
            body: formData
        };

        const response = await fetch(`${serverUrl}/v1/images/regulator/${regId}`, config);

        return this.handleResponse(response);
    }

    public async clearImage(regId: string): Promise<Response>
    {
        const config: RequestInit = {
            credentials: "include",
            method: "DELETE"
        };

        const response = await fetch(`${serverUrl}/v1/images/regulator/${regId}`, config);

        return this.handleResponse(response);
    }
}
