import React from "react";
import Box from "@mui/material/Box/Box";
import Container from "@mui/material/Container/Container";

export const DefaultPadding: React.FC = (props) => {
    return (
        <Box py={4}>
            <Container>
                <>{props.children}</>
            </Container>
        </Box>
    )
};
