import {css} from "@emotion/react";
import Chip from "@mui/material/Chip";
import React from "react";
import { useTheme } from '@mui/material/styles';
import {GasType} from "../../../api/entityApis/GasTypeApi";

export const GasTypeChip = (props: {gasType: GasType}) => {

    const theme = useTheme();

    return (
        <Chip size="medium"
              css={css`
                    margin-left: 3px; 
                    margin-right: 3px; 
                    background-color: ${props.gasType.colour};
                    color: ${props.gasType.darkFont ? theme.palette.common.black : theme.palette.common.white}
                  `}
              label={props.gasType.gasType}
        />
    )
}
