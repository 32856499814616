import {GemApi, serverUrl} from "./GemApi";

export class WhoAmIApi extends GemApi {

    public async whoAmI(): Promise<Response> {
        const config: RequestInit = {
            credentials: "include",
            method: "GET"
        };

        const response = await fetch(`${serverUrl}/v1/whoami`, config);

        return this.handleResponse(response);
    }
}