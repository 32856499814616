import {EntityConstants} from "./EntityConstants";
import {PreCreationRegulator, Regulator, RegulatorApi} from "../api/entityApis/RegulatorApi";
import {convertFormToRegulator} from "../components/SingleEntity/Regulator/convertFormToRegulator";
import {regulatorReducer} from "../state/reducers/regulatorReducer";
import {RegulatorFields} from "../components/SingleEntity/Regulator/RegulatorFields";

export const regulatorConstants: EntityConstants<PreCreationRegulator, Regulator> = {
    name: "Regulator",
    uri: "regulators",
    api: RegulatorApi,
    convertFormToEntity: convertFormToRegulator,
    reducer: regulatorReducer,
    fields: RegulatorFields,
    getData: (state => state.regulators)
}
