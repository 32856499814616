import {TextField} from "@mui/material";
import React from "react";
import {UseFormReturn} from "react-hook-form";

/**
 * Helper component to edit a string field.
 */
export const EditStringField: React.FC<{
    form: UseFormReturn
    defaultValue?: string
    name: string
}> = (props) => {
    return (
        <TextField
            {...props.form.register(props.name)}
            defaultValue={props.defaultValue}
            fullWidth
        />
    )
}
