import {SingleField} from "../Shared/SingleField/SingleField";
import {AccessTime, CalendarToday, FontDownload} from "@mui/icons-material";
import {Controller} from "react-hook-form";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BuildIcon from "@mui/icons-material/Build";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LabelIcon from "@mui/icons-material/Label";
import React, {useEffect} from "react";
import {parse} from "date-fns";
import format from 'date-fns/format'
import {ISingleEntityFields} from "../Shared/ISingleEntityFields";
import {PreCreationRegulator} from "../../../api/entityApis/RegulatorApi";
import {StringField} from "../Shared/SingleField/StringField";
import {ViewStringField} from "../Shared/SingleField/ViewStringField";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../state/rootReducer";
import {locationReducer} from "../../../state/reducers/locationReducer";
import {gasTypeReducer} from "../../../state/reducers/gasTypeReducer";
import {Autocomplete} from '@mui/material';
import {GasType} from "../../../api/entityApis/GasTypeApi";
import {GasTypeChips} from "../GasType/GasTypeChips";
import {TextField} from "@mui/material";
import AssignmentIcon from '@mui/icons-material/Assignment';
import {GemLocation} from "../../../api/entityApis/LocationApi";
import Box from "@mui/material/Box";
import {RegulatorImage} from "./RegulatorImage";
import {DatePicker} from "@mui/x-date-pickers";
import {css} from "@emotion/react";
import {TextFieldProps as MuiTextFieldPropsType} from "@mui/material/TextField/TextField";
import {ServiceHistoryTable} from "./ServiceHistoryTable";
import {ServiceHistoryEntry} from "../../../api/entityApis/ServiceHistoryEntryApi";
import {SingleFieldGrid} from "../Shared/SingleField/SingleFieldGrid";

export const RegulatorFields: React.FC<ISingleEntityFields<PreCreationRegulator>> = (props) => {

    const locations = useSelector((state: RootState) => state.locations);
    const gasTypes = useSelector((state: RootState) => state.gasTypes);

    const dispatch = useDispatch<any>();

    useEffect(() => {
        props.form.register("gasTypes")
        props.form.setValue("gasTypes", props.entity.gasTypes)
        props.form.register("location", {required: true})
        props.form.setValue("location", props.entity.location?.id)
        props.form.register("serviceHistoryEntries")
        props.form.setValue("serviceHistoryEntries", props.entity.serviceHistoryEntries)


    }, [props.entity.gasTypes, props.entity.location?.id, props.entity.serviceHistoryEntries, props.form])

    useEffect(() => {
        dispatch(locationReducer.fetchResources());
        dispatch(gasTypeReducer.fetchResources());
    }, [dispatch]);

    function ServiceHistoryFunction(rows: ServiceHistoryEntry[], newDate: string) {

        /* Rows is an array of service history entries.
        *
        * I also don't know when I'm going to be calling this function. I think it should be whenever something
        * Changes in the service history form.
        *
        *  */

        props.form.setValue("serviceDueDate", newDate)
        props.form.setValue("serviceHistoryEntries", rows)

    }


    return <>

        <SingleField editMode={props.editMode} title="Type" icon={AccessTime}
                     editComponent={
                         <Controller control={props.form.control}
                                     name="type"
                                     defaultValue={props.entity.type}
                                     render={({field}) =>
                                         <Select {...field} fullWidth>
                                             <MenuItem value="REGULATOR">REGULATOR</MenuItem>
                                             <MenuItem value="MANIFOLD">MANIFOLD</MenuItem>
                                         </Select>
                                     }
                         />
                     }
                     viewComponent={<ViewStringField text={props.entity.type}/>}
        />

        <SingleField
            editMode={props.editMode} title="Location" icon={LocationOnIcon}
            editComponent={
                <Autocomplete
                    disableClearable
                    defaultValue={props.entity.location}
                    onChange={(_, value) => {
                        if (value) {
                            props.form.setValue("location", value.id)
                        }
                    }}
                    options={locations}
                    // @ts-ignore
                    getOptionLabel={(option: GemLocation) => option.locationCode}
                    isOptionEqualToValue={((option, value) => option.id === value.id)}
                    renderInput={(params) => {

                        if (props.form.formState.errors.location) {
                            return <TextField {...params} error helperText="A location must be specified."/>;
                        }

                        return <TextField{...params}/>;
                    }}
                />
            }
            viewComponent={<ViewStringField text={props.entity.location?.locationCode}/>}
        />

        <SingleField
            editMode={props.editMode} title="Gas Type" icon={AvTimerIcon}
            editComponent={
                <Autocomplete
                    defaultValue={props.entity.gasTypes}
                    onChange={(_, value) => {
                        props.form.setValue("gasTypes", value)
                    }}
                    multiple
                    disableCloseOnSelect
                    disableClearable
                    options={gasTypes}
                    // @ts-ignore
                    getOptionLabel={(option: GasType) => option.gasType}
                    isOptionEqualToValue={((option, value) => option.id === value.id)}
                    renderInput={(params) => <TextField {...params}/>}
                    renderTags={(values => <Box><GasTypeChips maxChips={8} gasTypes={values}/></Box>)}
                />
            }
            viewComponent={<GasTypeChips maxChips={8} gasTypes={props.entity.gasTypes}/>}
        />

        <StringField
            editMode={props.editMode}
            title="Manufacturer"
            value={props.entity.manufacturer}
            icon={BuildIcon}
            form={props.form}
            name="manufacturer"
        />

        <StringField
            editMode={props.editMode}
            title="Part Number"
            value={props.entity.manufacturerPartNum}
            icon={FontDownload}
            form={props.form}
            name="manufacturerPartNum"
        />

        <SingleField editMode={props.editMode} title="Modified?" icon={ChangeHistoryIcon}
                     editComponent={
                         <Controller control={props.form.control}
                                     name="modified"
                                     defaultValue={props.entity.modified}
                                     render={({field}) =>
                                         <Select {...field} fullWidth>
                                             <MenuItem value="Yes">Yes</MenuItem>
                                             <MenuItem value="No">No</MenuItem>
                                         </Select>}/>
                     }
                     viewComponent={<ViewStringField text={props.entity.modified}/>}
        />

        <StringField
            editMode={props.editMode}
            title="Serial Number"
            name="serialNum"
            value={props.entity.serialNum}
            icon={FontDownload}
            form={props.form}
        />

        <SingleField editMode={props.editMode} title="In Service" icon={LabelIcon}
                     editComponent={
                         <Controller control={props.form.control}
                                     name="inService"
                                     defaultValue={props.entity.inService ? "Yes" : "No"}
                                     render={({field}) =>
                                         <Select {...field} fullWidth>
                                             <MenuItem value="Yes">Yes</MenuItem>
                                             <MenuItem value="No">No</MenuItem>
                                         </Select>}/>
                     }
                     viewComponent={<ViewStringField text={props.entity.inService ? "Yes" : "No"}/>}
        />

        <SingleField editMode={props.editMode} title="Manufacture Date" icon={CalendarToday}
                     editComponent={
                         <Controller
                             control={props.form.control}
                             // Value and onChange have to be present even
                             // though the component is uncontrolled.
                             name="manufactureDate"
                             defaultValue={parseDateString(props.entity.manufactureDate)}
                             render={({field}) =>
                                 <DatePicker
                                     {...field}
                                     renderInput={(props: MuiTextFieldPropsType) => <TextField
                                         css={css`width: 100%`} {...props} />}
                                     inputFormat="dd/MM/yyyy"
                                 />
                             }
                         />
                     }
                     viewComponent={
                         <ViewStringField text={parseStringDate(parseDateString(props.entity.manufactureDate))}/>}
        />


        <SingleField editMode={props.editMode} title="Max Pressure (KPa)" icon={AvTimerIcon}
                     editComponent={
                         <TextField
                             {...props.form.register("maxPressure", {pattern: /\d/})}
                             defaultValue={props.entity.maxPressure}
                             error={!!props.form.formState.errors.maxPressure}
                             helperText={props.form.formState.errors.maxPressure && "Max pressure must be a number."}
                             type="number"
                             fullWidth
                         />
                     }
                     viewComponent={<ViewStringField text={props.entity.maxPressure}/>}
        />
        <SingleField editMode={props.editMode} title="Description" icon={AssignmentIcon}
                     editComponent={
                         <TextField
                             {...props.form.register("description")}
                             defaultValue={props.entity.description}
                             fullWidth
                             multiline
                         />
                     }

                     viewComponent={
                         <ViewStringField text={props.entity.description}/>
                     }

        />


        <SingleField editMode={props.editMode} title="Service Due Date" icon={CalendarToday}
                     editComponent={
                         <Controller
                             control={props.form.control}
                             // Value and onChange have to be present even
                             // though the component is uncontrolled.
                             name="serviceDueDate"
                             defaultValue={parseDateString(props.entity.serviceDueDate)}
                             render={({field}) =>
                                 <DatePicker
                                     {...field}
                                     renderInput={(props: MuiTextFieldPropsType) => <TextField
                                         css={css`width: 100%`} {...props} />}
                                     inputFormat="dd/MM/yyyy"
                                 />
                             }
                         />
                     }
                     viewComponent={
                         <ViewStringField text={parseStringDate(parseDateString(props.entity.serviceDueDate))}/>}
                     //parseStringDate(parseDateString(props.entity.serviceDueDate))
        />

        {
            props.entity.id ? <RegulatorImage id={props.entity.id} editMode={props.editMode}/> : null
        }

        {/* Beginning the service history */}
        {// <SingleFieldGrid title="Service History" icon={AssignmentIcon}></SingleFieldGrid>
        }

        <SingleFieldGrid title="Service History" icon={AssignmentIcon}>
            <ServiceHistoryTable
                id={props.entity.id}
                editMode={props.editMode}
                serviceHistoryEntries={props.entity.serviceHistoryEntries}
                ServiceHistoryCallbackFunction={ServiceHistoryFunction}
            />
        </SingleFieldGrid>

    </>;
}

const parseDateString = (date?: string) => {
    if (date === null || date === undefined) {
        return null;
    }

    return parse(date, "yyyy-MM-dd", 0)

}

const parseStringDate = (date?: any) => {

    if (date === null || date === undefined) {
        return null;
    }
    return format(date, "dd/MM/yyyy")
}
