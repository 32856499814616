import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../state/rootReducer";
import {ViewEntity} from "../Shared/ViewEntity";
import React, {useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import {regulatorConstants} from "../../../constants/RegulatorConstants";
import {serverAuditActions} from "../../../state/reducers/serverAudit/auditReducer";

export const ViewRegulator: React.FC = (props) => {

    const params = useParams<{ id: string }>();
    const id = parseInt(params.id ?? "-1");

    const dispatch = useDispatch();

    const regulators = useSelector((state: RootState) => state.regulators, shallowEqual);
    const audit = useSelector((state: RootState) => state.audit, shallowEqual);

    // URL stuff.
    const search = new URLSearchParams(useLocation().search);
    const isScanned = search.get("scan") != null;

    // We have the ID from the URL, find the appropriate regulator with it.
    const regulator = regulators.find(regulator => regulator.id === id)

    /**
     * Then, do a second lookup to get more up-to-date info on this specific regulator.
     *
     * useEffect is a React Hook made for triggering "side effects", including API calls.
     * https://reactjs.org/docs/hooks-effect.html
     */
    useEffect(() => {
        if (audit.auditMode && isScanned && regulator) {
            dispatch(serverAuditActions.auditRegulator({regulator}))
        }
    }, [dispatch, audit.auditMode, regulator, isScanned]);

    return (
        <ViewEntity
            entityArray={regulators}
            entityConstants={regulatorConstants}
        />
    )
}
