import React from 'react';
import QRCode from "qrcode.react";
import {useTheme} from '@mui/material/styles';

// const gem = require('./GEM.jpg');

/**
 *
 *   Takes in the regulator ID as a string in order to generate a QR code that links to the endpoint
 *   http://localhost:8080/v1/regulators/{id}
 *   It then returns a canvas containing the completed QR code
 *
 */
export const GenerateEntityQRCode: React.FC<{
    uri: string
    id: number
    size?: number
}> = (props) => {

    const theme = useTheme();

    return (

        <QRCode
            value={`${window.location.origin}/${props.uri}/${props.id}?scan`}
            size={props.size? props.size : 256}
            bgColor={theme.palette.background.paper}
            fgColor={theme.palette.text.primary}
            level={"M"}
            includeMargin={false}
            //renderAs={"canvas"}
            renderAs={"svg"}

            // imageSettings={{
            //     src: gem,
            //     x: 96,
            //     y: 96,
            //     height: 32,
            //     width: 32,
            //     excavate: true
            // }}
        />
    );
};

// TODO remove this and make audit use location QR codes
export const GenerateLocationQrCode = (props: { location: string }) => {

    const theme = useTheme();

    return (
        <QRCode
            value={`${window.location.origin}/audit/${props.location}`}
            size={256}
            bgColor={theme.palette.background.paper}
            fgColor={theme.palette.text.primary}
            level={"M"}
            includeMargin={false}
            renderAs={"canvas"}
        />
    )
};
