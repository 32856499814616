import {GemUser, UserApi} from "../../api/entityApis/UserApi";
import {CrudReducer} from "./crudReducer";

class UserReducer extends CrudReducer<GemUser>
{
    getApi() {
        return UserApi;
    }

    getName() {
        return "user";
    }
}

export const userReducer = new UserReducer();
