import {useSelector} from "react-redux";
import {RootState} from "../../state/rootReducer";
import React from "react";
import {MUIDataTableColumn} from "mui-datatables";
import {CrudTable} from "./CrudTable";
import {PrintButton} from "../Print/PrintButton";
import {locationConstants} from "../../constants/LocationConstants";

export const LocationTable = () => {

    const locations = useSelector((state: RootState) => state.locations);

    const tableColumns: MUIDataTableColumn[] = [
        {
            name: "id",
            options: {
                display: "excluded"
            }
        },
        {
            label: "Location Code",
            name: "locationCode",
        }
    ];

    return (
        <CrudTable
            tableColumns={tableColumns}
            tableData={locations}
            entityConstants={locationConstants}
            extraToolbarSelectComponents={[PrintButton]}
        />
    )
};
