import {PreCreationGemLocation} from "../../../api/entityApis/LocationApi";

export const convertFormToLocation = (data: any): PreCreationGemLocation => {

    console.debug(data);

    return {
        locationCode: data.locationCode,
    }
};
