import {useSelector} from "react-redux";
import {RootState} from "../../state/rootReducer";
import React from "react";
import {MUIDataTableColumn, MUIDataTableOptions} from "mui-datatables";
import {CrudTable} from "./CrudTable";
import {PrintButton} from "../Print/PrintButton";
import {regulatorConstants} from "../../constants/RegulatorConstants";

/**
 * TODO work out how to track selected column state for a user.
 * There is a callback when this changes so we can in theory use that?
 *
 * TODO when doing this manually for mobile, use react-lazyload.
 * @constructor
 */



export const ServicesDueTable: React.FC<{
}> = (props) => {

    let customOptions:MUIDataTableOptions = {
        selectableRows: "none",
        customToolbarSelect: undefined,
        customToolbar: undefined,
        sortOrder: {
            name: 'serviceDueDate',
            direction: 'asc'
        }
    }

    const regulators = useSelector((state: RootState) => state.regulators);
    const tableColumns: MUIDataTableColumn[] = [
        {
            label: "ID",
            name: "id"
        },
        {
            label: "Location",
            name: "location.locationCode"
        },
        {
            label: "Service Due",
            name: "serviceDueDate"
        },

    ];


    return (
        <CrudTable
            tableColumns={tableColumns}
            tableData={regulators.filter(x => x.serviceDueDate)}
            tableOptions={customOptions}
            entityConstants={regulatorConstants}
            extraToolbarSelectComponents={[PrintButton]}
        />
    )
};




