import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../state/rootReducer";
import React, {useEffect} from "react";
import {MUIDataTableColumn} from "mui-datatables";
import {CrudTable} from "./CrudTable";
import {PrintButton} from "../Print/PrintButton";
import {regulatorConstants} from "../../constants/RegulatorConstants";
import {GasTypeChips} from "../SingleEntity/GasType/GasTypeChips";
import {GasType} from "../../api/entityApis/GasTypeApi";
import {Checkbox, FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import {gasTypeReducer} from "../../state/reducers/gasTypeReducer";

/**
 * TODO work out how to track selected column state for a user.
 * There is a callback when this changes so we can in theory use that?
 *
 * TODO when doing this manually for mobile, use react-lazyload.
 * @constructor
 */



export const RegulatorTable: React.FC<{
}> = (props) => {

    const regulators = useSelector((state: RootState) => state.regulators);
    const dispatch = useDispatch<any>();
    useEffect(() => {
        dispatch(gasTypeReducer.fetchResources())
    }, [dispatch])


    const gasTypes = useSelector((state: RootState) => state.gasTypes);
    const tableColumns: MUIDataTableColumn[] = [
        {
            label: "ID",
            name: "id"
        },
        {
            label: "Gas Types",
            name: "gasTypes",
            options: {
                // TODO how do we make gasType cells have negative margins, only on the mobile version of the table?
                /*
                customBodyRenderLite: (dataIndex, rowIndex) => {
                    return <GasTypeChips maxChips={2} gasTypes={regulators[dataIndex].gasTypes}/>
                },
                 */
                customBodyRender: (value: GasType[]) => {

                    if (value.length > 0) {
                        return <GasTypeChips maxChips={2} gasTypes={value}/>
                    }
                    return null;
                },
                filterType: "custom",
                filterOptions: {
                    logic: (gasTypes, filters, row) => {

                        let status: boolean = false;
                        if (filters.length > 0 && row) {
                            // If equipment has no gas type, it is filtered out.
                            if (row[1].length === 0) {
                                return true;
                            }
                            for (let gas of row[1]) {
                                if (!filters.includes(gas.gasType)) {
                                    status = true;
                                }
                            }
                        }
                        return status;
                    },
                    display: (filterList, onChange, index, column) => {
                        //console.log(filterList);

                        return (
                            <FormControl key={index} variant="standard" fullWidth>
                                <InputLabel shrink htmlFor='select-multiple-chip'>
                                    Gas Types
                                </InputLabel>
                                <Select
                                    fullWidth
                                    multiple
                                    value={filterList[index]}
                                    renderValue={selected => {

                                        const gas = selected
                                            .map(x => gasTypes.find(g => g.gasType === x))
                                            .filter((g): g is GasType => !!g);

                                        return <GasTypeChips gasTypes={gas} maxChips={2}/>
                                    }}
                                    onChange={event => {
                                        // @ts-ignore
                                        filterList[index] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}
                                >
                                    {gasTypes.map(item => (
                                        item.gasType ?
                                            <MenuItem key={item.gasType} value={item.gasType}>
                                                <Checkbox color='primary'
                                                          checked={filterList[index].indexOf(item.gasType) > -1}/>
                                                <GasTypeChips maxChips={1} gasTypes={[item]}/>
                                            </MenuItem>
                                            : <></>
                                    ))}
                                </Select>
                            </FormControl>
                        );
                    }
                },
                sortCompare: order => ((obj1: { data: GasType[] }, obj2: { data: GasType[] }) => {

                    const gasTypes1 = obj1.data.map(it => it.gasType).join(",")
                    const gasTypes2 = obj2.data.map(it => it.gasType).join(",")

                    let result = gasTypes1.localeCompare(gasTypes2)

                    // Extra checks to put empty strings last rather than first.
                    if (gasTypes2.length === 0) result = -1
                    else if (gasTypes1.length === 0) result = 1

                    return result * (order === "asc" ? 1 : -1)

                })
            }
        },
        {
            label: "Location",
            name: "location.locationCode"
        },
        {
            label: "Manufacturer",
            name: "manufacturer",
            options: {
                display: "false"
            }
        },
        {
            label: "Modified",
            name: "modified",
            options: {
                display: "false"
            }
        },
    ];


    return (
        <CrudTable
            tableColumns={tableColumns}
            tableData={regulators}
            entityConstants={regulatorConstants}
            extraToolbarSelectComponents={[PrintButton]}
        />
    )
};




