import {ViewEntity} from "../Shared/ViewEntity";
import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../state/rootReducer";
import {locationConstants} from "../../../constants/LocationConstants";
import Button from "@mui/material/Button";
import {completeAudit, serverAuditActions} from "../../../state/reducers/serverAudit/auditReducer";
import {useLocation, useParams} from "react-router-dom";
import {regulatorConstants} from "../../../constants/RegulatorConstants";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

export const ViewLocation: React.FC = () => {

    const dispatch = useDispatch<any>();

    const locations = useSelector((state: RootState) => state.locations);
    const audit = useSelector((state: RootState) => state.audit);

    // URL stuff.
    const params = useParams<{ id: string }>();
    const id = parseInt(params.id ?? "-1");
    const search = new URLSearchParams(useLocation().search);
    const isScanned = search.get("scan") != null;

    // We have the ID from the URL, find the appropriate location with it.
    const location = locations.find(location => location.id === id)

    const currentlyAuditingThisLocation = audit.currentAudit && location &&
        audit.currentAudit.locationId === location.id

    /**
     * Starts a new audit. This will complete any existing audits.
     * If the in-progress audit is for the same location, don't start a new audit.
     */
    const startAudit = useCallback(async () => {

        if (!location) {
            throw Error("Invalid state. Should not be able to start audit without a loaded location.")
        }

        // There's already an audit, complete and await the existing one before starting the new one.
        if (audit.auditMode) {

            await dispatch(completeAudit(audit))
        }

        if (!currentlyAuditingThisLocation) {
            const regulators = await regulatorConstants.api.getAll();

            dispatch(serverAuditActions.startAudit({
                locationCode: location.locationCode,
                locationId: location.id,
                allRegulators: regulators
            }));
        }
    }, [audit, currentlyAuditingThisLocation, dispatch, location])

    /**
     * A location is loaded and the user has gotten to this page via scanning a QR code.
     * Begin an audit.
     */
    useEffect(() => {

        if (location && isScanned) {
            startAudit();
        }
    }, [location, isScanned, startAudit])

    return (
        <ViewEntity
            entityArray={locations}
            entityConstants={locationConstants}
        >
            <Grid item xs={12}>
                <Box m={2}>
                    <Button variant="contained" onClick={startAudit}>
                        {currentlyAuditingThisLocation ?
                            "Complete audit" :
                            "Start Audit"
                        }
                    </Button>
                </Box>
            </Grid>
        </ViewEntity>
    )
};
