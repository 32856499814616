import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../store";
import {CrudApi} from "../../api/CrudApi";

export abstract class CrudReducer<T>
{
    abstract getName(): string

    abstract getApi(): CrudApi<T, any>;

    private readonly resourceSlice = createSlice({
        name: this.getName(),
        initialState: [] as T[],
        reducers: {
            setResources: (state, action: PayloadAction<T[]>) => {
                return action.payload
            }
        }
    })

    public readonly setResources = this.resourceSlice.actions.setResources

    public readonly fetchResources = (): AppThunk => async dispatch => {
        const resources = await this.getApi().getAll();
        dispatch(this.setResources(resources));
    }

    public readonly deleteResources = (resources: number[]): AppThunk => async dispatch => {

        for (const resource of resources) {
            await this.getApi().delete(resource)
        }

        dispatch(this.fetchResources());
    }

    public readonly reducer = this.resourceSlice.reducer
}
