import {CrudReducer} from "./crudReducer";
import {GemLocation, LocationApi} from "../../api/entityApis/LocationApi";

class LocationReducer extends CrudReducer<GemLocation>
{
    getApi() {
        return LocationApi;
    }

    getName() {
        return "location";
    }
}

export const locationReducer = new LocationReducer();
