import React from "react";
import {ISingleEntityFields} from "../Shared/ISingleEntityFields";
import {PreCreationGemLocation} from "../../../api/entityApis/LocationApi";
import {StringField} from "../Shared/SingleField/StringField";
import LocationOnIcon from '@mui/icons-material/LocationOn';


export const LocationFields: React.FC<ISingleEntityFields<PreCreationGemLocation>> = (props) => {

    return (
        <>
        <StringField
            editMode={props.editMode}
            form={props.form}
            value={props.entity.locationCode}
            title="Location Code"
            name="locationCode"
            icon={LocationOnIcon}
        />
        </>
    )
};


