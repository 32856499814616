import Box from "@mui/material/Box";
import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../state/rootReducer";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import CardActions from "@mui/material/CardActions";
import {completeAudit} from "../../state/reducers/serverAudit/auditReducer";

export const Audit = () => {

    const audit = useSelector((state: RootState) => state.audit);

    const dispatch = useDispatch<any>();

    const stopAudit = () => {
        dispatch(completeAudit(audit));
    };

    return (
        <Card>
            <CardHeader title="Audit Mode"/>
            <CardContent>
                <Typography variant="h6" gutterBottom>{audit.currentAudit ?
                    `Audit in progress for location ${audit.currentAudit.locationCode}` :
                    "No audit in progress"}
                </Typography>
                {
                    audit.currentAudit ?
                        <Box py={4}>
                            <Typography variant="subtitle2" gutterBottom>Remaining regulators:</Typography>
                            <List>
                                <Divider/>
                                {audit.currentAudit.remainingRegulators.map(regulator => <div key={regulator.regulatorId}>
                                        <ListItem><ListItemText>Regulator {regulator.regulatorId}</ListItemText></ListItem>
                                        <Divider/>
                                    </div>
                                )}
                            </List>
                        </Box>
                        : null
                }
            </CardContent>
            <CardActions>
                {
                    audit.currentAudit ?
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={stopAudit}
                        >
                            Complete Audit
                        </Button>
                        : null
                }
            </CardActions>
        </Card>
    );
};
