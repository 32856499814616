import {ViewEntity} from "../Shared/ViewEntity";
import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../state/rootReducer";
import {userConstants} from "../../../constants/UserConstants";

export const ViewUser: React.FC = () => {

    const users = useSelector((state: RootState) => state.users);

    return (
        <ViewEntity
            entityArray={users}
            entityConstants={userConstants}
        />
    )
};
