import React from 'react';
import {GemRoutes} from './Routes';
import {Header} from "./Header";
import {FooterTabs} from "./Navigation/FooterTabs";
import {HeaderTabs} from "./Navigation/HeaderTabs";
import { useTheme } from '@mui/material/styles';
import {useMediaQuery} from "@mui/material";
import {css} from "@emotion/react";

/**
 * App component. This component lays out the structure of the application: whether to render the header and/or footer
 * based on the user's screen width.
 */
const App = () => {

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const backgroundColor = theme.palette.mode === "light" ? theme.palette.grey["100"] : "#121212";

    return (
        <div css={css`min-height: 100vh; background-color: ${backgroundColor}`}>
            <Header>
                {isMobile ? null : <HeaderTabs/>}
            </Header>
            <GemRoutes/>
            {isMobile ? <FooterTabs/> : null}
        </div>
    )
};

export default App;
