import React from "react";
import {ISingleEntityFields} from "../Shared/ISingleEntityFields";
import {StringField} from "../Shared/SingleField/StringField";
import FaceIcon from '@mui/icons-material/Face';
import {PreCreationAuditHistoryEntry} from "../../../api/entityApis/AuditHistoryApi";

export const AuditHistoryFields: React.FC<ISingleEntityFields<PreCreationAuditHistoryEntry>> = (props) => {

    return (
        <>
            <StringField
                editMode={props.editMode}
                form={props.form}
                value={props.entity.locationCode}
                title="Location Code"
                name="location"
                icon={FaceIcon}
            />
        </>
    )
};
