import {PayloadAction} from "@reduxjs/toolkit";
import format from "date-fns/format";
import {AuditState} from "./auditReducer";
import {Regulator} from "../../../api/entityApis/RegulatorApi";
import React from "react";
import {AuditedRegulator} from "../../../api/entityApis/AuditHistoryApi";
import {toast} from "react-toastify";

/**
 * Start an audit. Triggered from ViewLocation. Or by visiting ViewLocation from its QR code.
 */
export const startAudit = (state: AuditState, action: PayloadAction<{
    locationId: number
    locationCode?: string
    allRegulators: Regulator[]
}>) => {

    state.auditMode = true;

    const remainingRegulators = action.payload.allRegulators
        .filter(it => it.location?.id === action.payload.locationId)
        .map(regulator => {

            const auditedReg: AuditedRegulator = {
                regulatorLocationCode: regulator.location?.locationCode,
                regulatorLocationId: regulator.location?.id,
                regulatorId: regulator.id
            };

            return auditedReg;
        });

    state.currentAudit = {
        locationId: action.payload.locationId,
        locationCode: action.payload.locationCode,
        remainingRegulators: remainingRegulators,
        auditedRegulators: [],
        auditStartDate: format(Date.now(), "yyyy-MM-dd HH:mm:ss"),
        success: true
    }

    toast.info(<>Audit for location <b>{action.payload.locationCode}</b> started.</>)
};

/**
 * Audit a regulator. Triggered by visiting a regulator (via QR?) when audit mode is enabled.
 */
export const auditRegulator = (state: AuditState, action: PayloadAction<{
    regulator: Regulator
}>) => {

    if (!state.currentAudit)
    {
        throw Error("Invalid state. Regulator has been audited when audit mode is disabled.")
    }

    const {regulator} = action.payload
    const success = regulator.location?.id === state.currentAudit.locationId

    state.currentAudit.auditedRegulators.push({
        regulatorLocationCode: regulator.location?.locationCode,
        regulatorLocationId: regulator.location?.id,
        regulatorId: regulator.id,
    })

    // Remove the regulator's ID from the remaining regulators.
    state.currentAudit.remainingRegulators = state.currentAudit.remainingRegulators
        .filter(it => it.regulatorId !== action.payload.regulator.id)

    if (success)
    {
        toast.success("Regulator Audited.")
    } else {

        state.currentAudit.success = false

        toast.error(<>Regulator {regulator.id} should be in location <b>{regulator.location?.locationCode}</b>,
            not <b>{state.currentAudit?.locationCode}</b></>)
    }
}

/**
 * Manually complete an audit by either re-scanning its location or
 * scanning a new location.
 */
export const resetAudit = (state: AuditState) => {

    state.auditMode = false;
    state.currentAudit = undefined;
}
