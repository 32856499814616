import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../state/rootReducer";
import {ViewEntity} from "../Shared/ViewEntity";
import {auditHistoryConstants} from "../../../constants/AuditHistoryConstants";

export const ViewAuditHistoryEntry: React.FC = () => {

    const auditHistory = useSelector((state: RootState) => state.auditHistory);

    return (
        <ViewEntity
            entityArray={auditHistory}
            entityConstants={auditHistoryConstants}
        />
    )
};
