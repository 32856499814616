import {EntityConstants} from "./EntityConstants";
import {AuditHistoryApi, AuditHistoryEntry, PreCreationAuditHistoryEntry} from "../api/entityApis/AuditHistoryApi";
import {auditHistoryReducer} from "../state/reducers/auditHistoryReducer";
import {AuditHistoryFields} from "../components/SingleEntity/AuditHistory/AuditHistoryFields";

export const auditHistoryConstants: EntityConstants<PreCreationAuditHistoryEntry, AuditHistoryEntry> = {
    name: "Audit History",
    uri: "auditHistory",
    api: AuditHistoryApi,
    convertFormToEntity: (data) => data,
    reducer: auditHistoryReducer,
    fields: AuditHistoryFields,
    getData: (state => state.auditHistory)
}
