import React from "react";
import {Route, Routes} from "react-router-dom";
import {RegulatorTable} from "../Table/RegulatorTable";
import {DefaultPadding} from "./DefaultPadding";
import {useTheme} from '@mui/material/styles';
import {useMediaQuery} from "@mui/material";
import {Audit} from "../Audit/Audit";
import {UserTable} from "../Table/UserTable";
import {LocationTable} from "../Table/LocationTable";
import {ViewUser} from "../SingleEntity/User/ViewUser";
import {ViewRegulator} from "../SingleEntity/Regulator/ViewRegulator";
import {NewRegulator} from "../SingleEntity/Regulator/NewRegulator";
import {NewUser} from "../SingleEntity/User/NewUser";
import {NewLocation} from "../SingleEntity/Location/NewLocation";
import {ViewLocation} from "../SingleEntity/Location/ViewLocation";
import {GasTypeTable} from "../Table/GasTypeTable";
import {NewGasType} from "../SingleEntity/GasType/NewGasType";
import {ViewGasType} from "../SingleEntity/GasType/ViewGasType";
import {AuditHistoryTable} from "../Table/AuditHistoryTable";

import {HomePage} from "./HomePage";

import {ViewAuditHistoryEntry} from "../SingleEntity/AuditHistory/ViewAuditHistoryEntry";


/**
 * Main content of the app. Uses react-router to determine which page to display.
 */
export const GemRoutes = () => {

    const theme = useTheme();

    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Routes>
            <Route path="/regulators" element={
                isTablet
                    ? <RegulatorTable/>
                    : <DefaultPadding><RegulatorTable/></DefaultPadding>}
            />
            <Route path="/regulators/add" element={
                <DefaultPadding>
                    <NewRegulator/>
                </DefaultPadding>
            }/>
            <Route path="/regulators/:id" element={
                <DefaultPadding>
                    <ViewRegulator/>
                </DefaultPadding>
            }/>
            <Route path="/users" element={
                isTablet
                    ? <UserTable/>
                    : <DefaultPadding><UserTable/></DefaultPadding>
                }/>
            <Route path="/users/add" element={
                <DefaultPadding>
                    <NewUser/>
                </DefaultPadding>
                }/>
            <Route path="/users/:id" element={
                <DefaultPadding>
                    <ViewUser/>
                </DefaultPadding>
                }/>
            <Route path="/locations" element={
                isTablet
                    ? <LocationTable/>
                    : <DefaultPadding><LocationTable/></DefaultPadding>
                }/>
            <Route path="/locations/add" element={
                <DefaultPadding>
                    <NewLocation/>
                </DefaultPadding>
                }/>
            <Route path="/locations/:id" element={
                <DefaultPadding>
                    <ViewLocation/>
                </DefaultPadding>
                }/>
            <Route path="/gasTypes" element={
                isTablet
                    ? <GasTypeTable/>
                    : <DefaultPadding><GasTypeTable/></DefaultPadding>
                }/>
            <Route path="/gasTypes/add" element={
                <DefaultPadding>
                    <NewGasType/>
                </DefaultPadding>
                }/>
            <Route path="/gasTypes/:id" element={
                <DefaultPadding>
                    <ViewGasType/>
                </DefaultPadding>
                }/>
            <Route path="/audit" element={
                <DefaultPadding>
                    <Audit/>
                </DefaultPadding>
                }/>
            <Route path="/auditHistory" element={
                isTablet
                    ? <AuditHistoryTable/>
                    : <DefaultPadding><AuditHistoryTable/></DefaultPadding>
                }/>
            <Route path="/auditHistory/:id" element={
                <DefaultPadding>
                    <ViewAuditHistoryEntry/>
                </DefaultPadding>
            }/>

            <Route path="/home" element={
                isTablet
                ? <HomePage/>
                : <DefaultPadding><HomePage/></DefaultPadding>
            }/>
            <Route path="/" element={
                isTablet
                    ? <HomePage/>
                    : <DefaultPadding><HomePage/></DefaultPadding>
                }/>
            {/* Home acts as a fallback. It will render when nothing else matches. */}
        </Routes>
    )
};
