import {CardContent} from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";


export const EntityFieldsGrid: React.FC = (props) => {
    return (
        <CardContent>
            <Grid container spacing={8}>
                {props.children}
            </Grid>
        </CardContent>
    );
}
